import React, { PropsWithChildren } from "react";
import { useHref, useLinkClickHandler, LinkProps } from "react-router-dom";

import { Link, PseudoBox, PseudoBoxProps } from "Atoms";
import { LinkType } from "Types";

type Props = {
    isExternal?: boolean;
    onClick?: () => void;
    variantColor?: string;
} & LinkProps &
    PseudoBoxProps &
    React.HTMLProps<HTMLAnchorElement> &
    PropsWithChildren;

const baseStyles = {
    textDecoration: "none",
    transition: " 0.2s",
    outline: "none",
    cursor: "pointer"
};

export const RouterLink: React.FC<Props> = React.forwardRef(
    ({ state, target, to, replace = false, variantColor = "gray", ...rest }, ref) => {
        const href = useHref(to);
        const handleRouterClick = useLinkClickHandler(to, { state, replace, target });
        return (
            <PseudoBox
                as={"a" as LinkType}
                href={href}
                onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    handleRouterClick(event);
                }}
                _hover={{
                    textDecoration: rest.textDecoration ? rest.textDecoration : "underline",
                    color: rest.color ? rest.color : `${variantColor}.900`
                }}
                _focus={{
                    boxShadow: "outline"
                }}
                {...baseStyles}
                {...rest}
            />
        );
    }
);
