import { Languagekey } from "LanguageProvider";

export type SortOptions = {
    text: string;
    value: string;
    default: boolean;
    label?: string;
    translationKey?: Languagekey;
    sortFunction: (itemA: any, itemB: any) => number;
};

export type PaginationSortOptions = {
    sortingDirection?: DIRECTION;
    sortingOrder?: number;
} & SortOptions;

export enum DIRECTION {
    ASC = "ASC",
    DESC = "DESC",
    NONE = "NONE"
}
