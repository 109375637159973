import React, { useState } from "react";
import { useField, FieldAttributes } from "formik-next";

import { Input, FormLabel, Box, InputProps, FormErrorMessage, FormHelperText } from "Atoms";
import { useApolloClient } from "react-apollo";
import { validateOrganisationNumber } from "../admin/utils/YupValidationFunctions";

type Props = FieldAttributes<InputProps> & { formLabel?: string; helperText?: string };

export const OrgNoInput: React.FC<Props> = ({ formLabel, helperText, ...rest }) => {
    const [orgNoErr, setOrgNoErr] = useState("");
    const client = useApolloClient();
    const [field, meta] = useField<{}>(rest);
    const errorTxt = orgNoErr || (meta.error && meta.touched ? meta.error : "");

    const onLookupOrgNo = async () => {
        if ((field.value as string).length >= 9) {
            const isOk = await validateOrganisationNumber(client, field.value);
            setOrgNoErr(!isOk ? "(OBS: Org nummer finns redan registrerat)" : "");
        }
    };

    return (
        <Box mb={6}>
            {formLabel && <FormLabel>{formLabel}</FormLabel>}
            <Input {...field} {...rest} onKeyUp={onLookupOrgNo} />
            {errorTxt && <FormErrorMessage>{errorTxt}</FormErrorMessage>}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};
