import React from "react";

import { getBundleItemNameFromMods } from "Utils";
import { NewList, NewListItem, NewListProps, Flex } from "Atoms";
import { getBundleModAddonPriceText } from "TempUtils";
import { OnlineAddonsList } from "Organisms";
import { OnlineText } from "OnlineComponents";

type Props = {
    bundleProductItems: any;
    hasOnlineCtx?: boolean;
} & NewListProps;

export const BundleProductItems: React.FC<Props> = ({ hasOnlineCtx = true, bundleProductItems, ...rest }) => (
    <NewList stretch={2} listStyle="none" {...rest}>
        {bundleProductItems.map((productItem: any, i: number) => {
            const priceText = productItem.modifications
                ? getBundleModAddonPriceText({
                      sizes: productItem.modifications.sizes ? [productItem.modifications.sizes] : [],
                      flavours: productItem.modifications.flavours ? [productItem.modifications.flavours] : []
                  })
                : "";
            let modificationName = null;
            if (productItem.modifications) {
                const modName = getBundleItemNameFromMods(productItem);
                modificationName = modName === productItem.name ? null : modName;
            }

            return (
                <NewListItem color="gray.600" key={i}>
                    <Flex>
                        <OnlineText hasOnlineCtx={hasOnlineCtx} as="span" flex="1">
                            {productItem.name}
                        </OnlineText>
                        {priceText && (
                            <OnlineText hasOnlineCtx={hasOnlineCtx} as="span">
                                {priceText}
                            </OnlineText>
                        )}
                    </Flex>

                    {modificationName && (
                        <Flex color="gray.600" pl={6}>
                            <Flex flex="1">
                                <OnlineText hasOnlineCtx={hasOnlineCtx} as="span">
                                    {" "}
                                    {modificationName}
                                </OnlineText>
                            </Flex>
                        </Flex>
                    )}
                    <OnlineAddonsList addons={productItem.addons} px={3} />
                </NewListItem>
            );
        })}
    </NewList>
);
