import React, { Children, cloneElement, forwardRef, useContext, useRef } from "react";

import { Flex, FlexProps } from "Atoms";
import { useTabListStyle } from "../tabStyles";
import { TabContext } from "../Tabs";
import { getValidChildren } from "Utils";

export type TabListProps = FlexProps;

export const TabList: React.FC<TabListProps> = forwardRef((props, ref) => {
    const { children, onKeyDown, onClick, ...rest } = props;

    const { index: selectedIndex, onManualTabChange, onChangeTab, isVertical } = useContext(TabContext);

    const orientation = isVertical ? "vertical" : "horizontal";

    const tabListStyleProps = useTabListStyle();

    const allNodes = useRef([]);

    const _children = getValidChildren(children);

    const clones = Children.map(_children, (child, index) => {
        const isSelected = index === selectedIndex;

        const handleClick = (event: any) => {
            //@ts-ignore
            allNodes.current[index].focus();

            onManualTabChange(index);
            onChangeTab(index);

            //@ts-ignore
            if (child && child.props.onClick) {
                //@ts-ignore
                child.props.onClick(event);
            }
        };

        //@ts-ignore
        return cloneElement(child, {
            //@ts-ignore
            ref: node => (allNodes.current[index] = node),
            isSelected,
            onClick: handleClick
        });
    });

    return (
        <Flex ref={ref} role="tablist" aria-orientation={orientation} {...tabListStyleProps} {...rest}>
            {clones}
        </Flex>
    );
});
