import React, { forwardRef } from "react";

import { Box, BaseBoxProps } from "Atoms";
import { assignRef } from "FunctionUtils";

interface ITabPanel {
    isSelected?: boolean;
    selectedPanelRef?: React.RefObject<HTMLElement>;
}

export type TabPanelProps = ITabPanel & BaseBoxProps;

export const TabPanel: React.FC<TabPanelProps> = forwardRef(
    ({ children, isSelected, selectedPanelRef, ...rest }, ref) => (
        <Box
            ref={(node: any) => {
                if (isSelected) {
                    assignRef(selectedPanelRef, node);
                }
                assignRef(ref, node);
            }}
            role="tabpanel"
            tabIndex={-1}
            hidden={!isSelected}
            outline={0}
            {...rest}
        >
            {children}
        </Box>
    )
);
