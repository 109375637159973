import React, { forwardRef } from "react";

import { Box, BaseBoxProps, IInput, inputSizes } from "Atoms";

interface IInputElement {
    size?: IInput["size"];
    placement?: "left" | "right";
    children: React.ReactNode;
}

type InputElementProps = IInputElement & BaseBoxProps;

const InputElement: React.FC<InputElementProps> = forwardRef(
    ({ size = "md", children, placement = "left", ...props }, ref) => {
        // @ts-ignore
        const height = inputSizes[size] && inputSizes[size]["height"];
        // @ts-ignore
        const fontSize = inputSizes[size] && inputSizes[size]["fontSize"];
        const placementProp = { [placement]: "0" };

        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                height={height}
                width={height}
                fontSize={fontSize}
                top="0"
                zIndex={1}
                ref={ref}
                {...placementProp}
                {...props}
            >
                {children}
            </Box>
        );
    }
);

const InputLeftElement: React.FC<Omit<InputElementProps, "placement">> = props => (
    <InputElement placement="left" {...props} />
);

const InputRightElement: React.FC<Omit<InputElementProps, "placement">> = props => (
    <InputElement placement="right" {...props} />
);

export { InputElement, InputLeftElement, InputRightElement };
