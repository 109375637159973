import React from "react";
import { useField, FieldAttributes } from "formik-next";

import { BaseBoxProps, Box, Checkbox, FormErrorMessage, FormHelperText } from "Atoms";

type Props = {
    wrapperProps?: BaseBoxProps;
} & FieldAttributes<{ formLabel?: any; helperText?: string; isDisabled?: boolean }>;

export const FormCheckBox: React.FC<Props> = ({ formLabel, helperText, isDisabled, wrapperProps, ...rest }) => {
    const [field, meta] = useField<{}>(rest);
    const errorTxt = meta.error && meta.touched ? meta.error : "";

    return (
        <Box role="group" mb={6} {...wrapperProps}>
            <Checkbox name={field.name} checked={!!field.value} onChange={field.onChange} disabled={isDisabled}>
                <Box as="span" marginLeft={1}>
                    {formLabel}
                </Box>
            </Checkbox>
            {errorTxt && <FormErrorMessage>{errorTxt}</FormErrorMessage>}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};
