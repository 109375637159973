import React from "react";
import { useField, ErrorMessage, FieldAttributes } from "formik-next";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import { Input, Flex, FormLabel, RequiredIndicator, FormErrorMessage, FormHelperText, FlexProps } from "Atoms";
import { StyledDatePickerWrapper } from "./components";

type DatePickerProps = Omit<ReactDatePickerProps, "onChange">;

type Props = FieldAttributes<DatePickerProps> & {
    formLabel?: string;
    helperText?: string;
    isMandatory?: boolean;
    fullWidth?: boolean;
    wrapperProps?: FlexProps;
};

export const DatePickerInput: React.FC<Props> = ({
    formLabel,
    helperText,
    isMandatory,
    locale = "sv-se",
    fullWidth = false,
    wrapperProps,
    ...rest
}) => {
    const [{ value, onBlur, ...field }, , helpers] = useField(rest);

    return (
        <Flex direction="column" mb={6} {...wrapperProps}>
            {formLabel && (
                <FormLabel>
                    {formLabel}
                    {isMandatory && <RequiredIndicator />}
                </FormLabel>
            )}
            <StyledDatePickerWrapper fullWidth={fullWidth}>
                {/** @ts-ignore */}
                <DatePicker
                    {...field}
                    {...rest}
                    customInput={<Input fullWidth={fullWidth} />}
                    locale={locale}
                    //@ts-ignore
                    selected={(value && value) || null}
                    //@ts-ignore
                    onChange={val => helpers.setValue(val)}
                />
            </StyledDatePickerWrapper>
            <ErrorMessage name={field.name} component={FormErrorMessage} />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Flex>
    );
};
