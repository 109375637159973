import React, { createContext } from "react";
import { AiFillWarning } from "@react-icons/all-files/ai/AiFillWarning";
import { BsInfoCircleFill } from "@react-icons/all-files/bs/BsInfoCircleFill";
import { IoIosCheckmarkCircle } from "@react-icons/all-files/io/IoIosCheckmarkCircle";
import { IoIosCloseCircleOutline } from "@react-icons/all-files/io/IoIosCloseCircleOutline";
import { MdError } from "@react-icons/all-files/md/MdError";

import { BaseBoxProps, Box, IconButton } from "Atoms";
import { alertIconStyle, alertStyles } from "./styles";

export type AlertStatus = "error" | "success" | "info" | "warning";
export type AlertVariant = "solid" | "subtle";
export type AlertAccent = "top" | "left" | "right" | "bottom" | "none";

interface IAlert {
    status: AlertStatus;
    variant?: AlertVariant;
    accent?: AlertAccent;
}
interface IAlertDismissHandler {
    onClick: Function | undefined;
}

type AlertProps = IAlert & BaseBoxProps;

type AlertDismissProps = IAlertDismissHandler;

const statuses = {
    error: {
        icon: MdError,
        color: "red"
    },
    success: {
        icon: IoIosCheckmarkCircle,
        color: "green"
    },
    info: {
        icon: BsInfoCircleFill,
        color: "blue"
    },
    warning: {
        icon: AiFillWarning,
        color: "yellow"
    }
};

const AlertContext = createContext<IAlert | null>(null);

const useAlert = () => {
    const ctx = React.useContext(AlertContext);
    if (!ctx) {
        throw new Error("useAlert must be within AlertProvider");
    }
    return ctx;
};

const Alert: React.FC<AlertProps> = ({ status, variant = "subtle", accent = "none", ...rest }) => {
    const value = { status, variant };

    const styles = alertStyles({
        variant,
        accent,
        color: statuses[status].color
    });

    return (
        <AlertContext.Provider value={value}>
            <Box role="alert" {...styles} {...rest} />
        </AlertContext.Provider>
    );
};

const AlertTitle: React.FC<BaseBoxProps> = props => <Box fontWeight="bold" lineHeight="normal" mr={3} {...props} />;

const AlertBody: React.FC<BaseBoxProps> = props => <Box {...props} />;

const AlertDismissIcon: React.FC<AlertDismissProps> = ({ onClick }) => {
    const { variant, status } = useAlert();
    const iconStyles = alertIconStyle({
        variant: variant!,
        color: statuses[status].color
    });

    // TODO: Migrate CloseButton to BaseBoxProps  and use that instead later
    // return <CloseButton m={3} onClick={onClick} position="absolute" size="md" {...iconStyles} {...rest} />;
    return (
        <IconButton
            icon={IoIosCloseCircleOutline}
            position="absolute"
            size="sm"
            fontSize="1.5rem"
            top="6px"
            right="6px"
            rounded="50%"
            backgroundColor="transparent"
            onClick={() => onClick && onClick()}
            {...iconStyles}
        />
    );
};

const AlertIcon: React.FC<BaseBoxProps> = props => {
    const { variant, status } = useAlert();

    const { icon } = statuses[status];

    const iconStyles = alertIconStyle({
        variant: variant!,
        color: statuses[status].color
    });

    return <Box as={icon} mr={3} size={6} {...iconStyles} {...props} />;
};

export { Alert, AlertTitle, AlertBody, AlertIcon, AlertDismissIcon };
