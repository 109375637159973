import React from "react";
import { Formik, Form, Field, FieldProps, ErrorMessage } from "formik-next";
import * as Yup from "yup";
import { IoIosClose } from "@react-icons/all-files/io/IoIosClose";

import { Box, Button, FormErrorMessage, FormLabel, Input } from "Atoms";
import { useLanguage } from "Providers";
import { IconButton, InputGroup, InputRightElement } from "Molecules";
import { LanguageHeader, LanguageText } from "Components";

export type ForgotPasswordFormValues = {
    email: string;
};

type Props = {
    handleForgotPassword: (values: ForgotPasswordFormValues) => Promise<void | undefined>;
};

export const ForgotPasswordForm: React.FC<Props> = ({ handleForgotPassword }) => {
    const { translate } = useLanguage();

    const forgotPasswordValidation = Yup.object().shape({
        email: Yup.string().email(translate("formErrorValidEmail")).required(translate("requiredError"))
    });

    return (
        <Formik<ForgotPasswordFormValues>
            validationSchema={forgotPasswordValidation}
            initialValues={{
                email: ""
            }}
            onSubmit={async (values, { setSubmitting }) => {
                await handleForgotPassword(values);
                setSubmitting(false);
            }}
        >
            {props => {
                const isSubmitDisabled = !props.values.email || !props.isValid || props.submitCount > 0;

                return (
                    <>
                        <LanguageHeader tid="forgotPassword" mb={4} size="lg" as="h3" />
                        <LanguageText tid="forgotPasswordEnterEmail" fontSize="xl" />
                        <Form>
                            <Field name="email">
                                {({ field, form }: FieldProps) => (
                                    <Box>
                                        <FormLabel isRequired>Email</FormLabel>
                                        <InputGroup size="lg">
                                            <Input
                                                pr="4.5rem"
                                                w="100%"
                                                id="email"
                                                type="email"
                                                placeholder="Email"
                                                {...field}
                                            />
                                            {!!field.value.length && (
                                                <InputRightElement width="4.5rem">
                                                    <IconButton
                                                        icon={IoIosClose}
                                                        onClick={() => form.setFieldValue("email", "")}
                                                        fontSize="22px"
                                                        size="sm"
                                                        tabIndex={-1}
                                                    />
                                                </InputRightElement>
                                            )}
                                        </InputGroup>
                                        <ErrorMessage name={field.name} component={FormErrorMessage} />
                                    </Box>
                                )}
                            </Field>
                            <Button
                                mt={8}
                                fullWidth
                                isLoading={props.isSubmitting}
                                size="lg"
                                themeColor="green"
                                type="submit"
                                isDisabled={isSubmitDisabled}
                            >
                                {translate("sendResetLink")}
                            </Button>
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
};
