import React from "react";

import { BaseBoxProps, Box, Flex, FlexProps } from "Atoms";

interface IDimmerContext {
    isActive: boolean;
}

interface IDimmer {
    isActive: boolean;
}

type DimmerProps = IDimmer & BaseBoxProps;
type DimmerContentProps = FlexProps;

const DimmerContext = React.createContext<IDimmerContext>({ isActive: false });
const useDimmer = () => React.useContext(DimmerContext);

export const Dimmer: React.FC<DimmerProps> = ({ isActive, children, ...rest }) => {
    if (!isActive) {
        return <>{children}</>;
    }

    const value = {
        isActive
    };

    return (
        <DimmerContext.Provider value={value}>
            <Box position="relative" {...rest}>
                {children}
            </Box>
        </DimmerContext.Provider>
    );
};

export const DimmerContent: React.FC<DimmerContentProps> = ({ children, ...rest }) => {
    const { isActive } = useDimmer();

    if (!isActive) {
        return null;
    }

    return (
        <Flex
            justify="center"
            align="center"
            zIndex={10}
            bg="blackAlpha.600"
            position="absolute"
            top="0"
            right="0"
            w="100%"
            height="100%"
            width="100%"
            color="white"
            {...rest}
        >
            {children}
        </Flex>
    );
};
