import React from "react";
import { useField, FieldAttributes, ErrorMessage } from "formik-next";

import { Input, FormLabel, Box, InputProps, FormErrorMessage, FormHelperText, Text, BaseBoxProps } from "Atoms";

type Props = FieldAttributes<InputProps> & {
    formLabel?: string;
    helperText?: string;
    isMandatory?: boolean;
    instantValidation?: boolean;
    wrapperProps?: BaseBoxProps;
};

export const FormInput: React.FC<Props> = ({
    formLabel,
    helperText,
    isMandatory = false,
    instantValidation = false,
    wrapperProps,
    component = Input,
    ...rest
}) => {
    const [field, meta] = useField<{}>(rest);

    const isInvalid = meta.touched && !!meta.error;

    const Component: any = component;

    return (
        <Box mb={6} {...wrapperProps}>
            {formLabel && (
                <FormLabel>
                    {formLabel}
                    {isMandatory && (
                        <Text as="span" color="red.500" ml={2}>
                            *
                        </Text>
                    )}
                </FormLabel>
            )}
            <Component isInvalid={isInvalid} {...field} {...rest} data-test={`input-form-${field.name}`} />
            <ErrorMessage name={field.name} component={FormErrorMessage} data-test={`input-form-error-${field.name}`} />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};
