import React from "react";

import { Flex, Box, Link, CopyrightText, BaseBoxProps } from "Atoms";
import { useLanguage } from "Providers/languageProvider/LanguageProvider";

export const MiniFooter: React.FC<BaseBoxProps> = props => {
    const { translate } = useLanguage();

    return (
        <Box as="footer" paddingX={12} paddingY={8} bg="gray.800" {...props}>
            <Flex justify="space-between">
                <CopyrightText margin="0" color="white" />
                <Link href="https://qopla.se/" isExternal color="white">
                    {translate("aboutQopla")}
                </Link>
            </Flex>
        </Box>
    );
};
