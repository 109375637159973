import React, { PropsWithChildren } from "react";

import { Box, BaseBoxProps } from "Atoms";

type TableProps = {
    wrapperProps?: BaseBoxProps;
    tableProps?: BaseBoxProps;
} & PropsWithChildren;

const Table: React.FC<TableProps> = ({ wrapperProps, tableProps, children }) => (
    <Box boxShadow="sm" rounded="lg" overflow="hidden" {...wrapperProps}>
        <Box as="table" width="full" {...tableProps}>
            {children}
        </Box>
    </Box>
);

const TableHead: React.FC<BaseBoxProps> = props => <Box as="thead" {...props} />;

const TableRow: React.FC<BaseBoxProps> = props => <Box as="tr" {...props} />;

const TableHeader: React.FC<BaseBoxProps> = props => (
    <Box
        as="th"
        px="6"
        py="3"
        borderBottomWidth="1px"
        backgroundColor="gray.100"
        textAlign="left"
        fontSize="md"
        color="gray.500"
        textTransform="uppercase"
        letterSpacing="wider"
        lineHeight="1rem"
        fontWeight="normal"
        {...props}
    />
);

const TableBody: React.FC<BaseBoxProps> = props => <Box as="tbody" {...props} />;

const TableFooter: React.FC<BaseBoxProps> = props => <Box as="tfoot" {...props} />;

const TableCell: React.FC<BaseBoxProps> = props => (
    <Box as="td" px="6" py="4" lineHeight="1.25rem" whiteSpace="nowrap" {...props} />
);

export { Table, TableHeader, TableRow, TableHead, TableBody, TableFooter, TableCell };
