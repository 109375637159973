import React from "react";
import { IconType } from "@react-icons/all-files/lib";

import { GridProps, Grid } from "../../atoms/Grid/Grid";
import { Box } from "Atoms";

export type IconTextWrapperProps = {
    text: string;
    icon: IconType;
} & GridProps;

export const IconTextWrapper: React.FC<IconTextWrapperProps> = ({ text, icon, ...rest }) => {
    return (
        <Grid height="auto" {...rest}>
            <Box as={icon} fontWeight="800" fontSize="1.3rem" color="newPrimary"></Box>
            <Box as="span" fontSize="1rem" textAlign="left" color="gray.800">
                {text}
            </Box>
        </Grid>
    );
};
