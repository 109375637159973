import React from "react";

import { Center, NewContainer } from "Atoms";
import { Languagekey, useLanguage } from "Providers/languageProvider/LanguageProvider";
import { Alert, AlertBody, AlertIcon, AlertTitle, LinkButton } from "Molecules";

type MustSelectPromptVariants = "selectedCompany" | "selectedShop";

type MustSelectPromptProps = {
    variant: MustSelectPromptVariants;
};

export const MustSelectPrompt: React.FC<MustSelectPromptProps> = ({ variant }) => {
    const { translate } = useLanguage();

    const { tid, linkTid, to } = {
        selectedCompany: {
            tid: "selectCompanyPrompt",
            linkTid: "selectCompanyHelper",
            to: "/admin/companies"
        },
        selectedShop: {
            tid: "selectShopPrompt",
            linkTid: "selectShopHelper",
            to: "/admin/shops"
        }
    }[variant];

    const text = translate(tid as Languagekey);
    const linkText = translate(linkTid as Languagekey);

    return (
        <NewContainer h="100%">
            <Center>
                <Alert
                    status="info"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                    height="200px"
                    rounded="md"
                    minW="sm"
                >
                    <AlertIcon size="40px" />
                    <AlertTitle mt={4} mb={1} fontSize="lg" color="gray.800">
                        {text}
                    </AlertTitle>
                    <AlertBody mt={4}>
                        <LinkButton to={to}>{linkText}</LinkButton>
                    </AlertBody>
                </Alert>
            </Center>
        </NewContainer>
    );
};
