import React, { createContext, forwardRef, useContext, useRef, useState } from "react";

import { Box, BaseBoxProps } from "Atoms";

export interface ITabs {
    align?: "start" | "center" | "end";
    variant?: "line" | "enclosed";
    isStretched?: boolean;
    isVertical?: boolean;
    size?: "sm" | "md" | "lg";
    onChange?: (index: number) => void;
    index?: number;
    defaultIndex?: number;
    themeColor?: string;
    isLazy?: boolean;
}

export type TabsProps = ITabs & Omit<BaseBoxProps, "onChange">;

interface ITabContext {
    index?: number;
    manualIndex?: number;
    onManualTabChange: (number: number) => void;
    onChangeTab: (number: number) => void;
    selectedPanelRef: any;
    onFocusPanel: () => void;
    color: any;
    size: "sm" | "md" | "lg";
    align: any;
    variant: "line" | "enclosed";
    isStretched: boolean;
    isVertical: boolean;
    isLazy: boolean;
}

export const TabContext = createContext<ITabContext>(null as any);

export const useTabsContext = () => useContext(TabContext);

export const Tabs: React.FC<TabsProps> = forwardRef(
    (
        {
            children,
            onChange,
            index: controlledIndex,
            defaultIndex,
            variant = "line",
            themeColor = "blue",
            align = "left",
            size = "md",
            isVertical = false,
            isStretched = false,
            isLazy = false,
            ...props
        },
        ref
    ) => {
        const { current: isControlled } = useRef(controlledIndex != null);
        const selectedPanelRef = useRef();

        const getInitialIndex = () => {
            return controlledIndex || defaultIndex || 0;
        };

        const getActualIdx = () => {
            return isControlled ? controlledIndex : selectedIndex;
        };

        const [selectedIndex, setSelectedIndex] = useState(getInitialIndex);
        const [manualIndex, setManualIndex] = useState(controlledIndex || defaultIndex || 0);

        let actualIdx = getActualIdx();
        let manualIdx = isControlled ? controlledIndex : manualIndex;

        const onChangeTab = (index: number) => {
            if (!isControlled) {
                setSelectedIndex(index);
            }

            if (isControlled) {
                onChange && onChange(index);
            }
        };

        const onManualTabChange = (index: number) => {
            if (!isControlled) {
                setManualIndex(index);
            }
        };

        const onFocusPanel = () => {
            if (selectedPanelRef.current) {
                //@ts-ignore
                selectedPanelRef.current.focus();
            }
        };

        const context = {
            index: actualIdx,
            manualIndex: manualIdx,
            onManualTabChange,
            onChangeTab,
            selectedPanelRef,
            onFocusPanel,
            color: themeColor,
            size,
            align,
            variant,
            isStretched,
            isVertical,
            isLazy
        };

        return (
            <TabContext.Provider value={context}>
                <Box display={isVertical ? "flex" : "block"} ref={ref} {...props}>
                    {children}
                </Box>
            </TabContext.Provider>
        );
    }
);
