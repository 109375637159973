import { AlertVariant, AlertAccent } from "./Alert";

type StyleProps = {
    variant: AlertVariant;
    color: string;
    accent: AlertAccent;
};

const baseStyles = {
    display: "flex",
    alignItems: "center",
    position: "relative",
    px: 4,
    py: 3
};

const solidStyles = (props: StyleProps) => {
    return {
        bg: `${props.color}.500`,
        color: `white`
    };
};

const subtleStyles = (props: StyleProps) => {
    return {
        bg: `${props.color}.200`,
        color: `${props.color}.700`
    };
};

const accentMap = {
    top: "Top",
    left: "Left",
    right: "Right",
    bottom: "Bottom",
    none: null
} as const;

const accentPositionStyles = (placement: AlertAccent) => (props: StyleProps) => {
    const _accent = accentMap[placement];

    if (_accent) {
        return {
            [`border${_accent}Width`]: "4px",
            [`border${_accent}Style`]: "solid",
            [`border${_accent}Color`]: props.variant === "solid" ? `${props.color}.300` : `${props.color}.600`
        };
    }
};

const topAccentStyles = accentPositionStyles("top");
const bottomAccentStyles = accentPositionStyles("bottom");
const leftAccentStyles = accentPositionStyles("left");
const rightAccentStyles = accentPositionStyles("right");

const variantStyles = (props: StyleProps) => {
    switch (props.variant) {
        case "solid":
            return solidStyles(props);

        case "subtle":
            return subtleStyles(props);

        default:
            return {};
    }
};

const accentStyles = (props: StyleProps) => {
    switch (props.accent) {
        case "top":
            return topAccentStyles(props);

        case "left":
            return leftAccentStyles(props);

        case "right":
            return rightAccentStyles(props);

        case "bottom":
            return bottomAccentStyles(props);

        default:
            return {};
    }
};

export const alertStyles = (props: StyleProps) => {
    return {
        ...baseStyles,
        ...variantStyles(props),
        ...accentStyles(props)
    };
};

export const alertIconStyle = (props: { color: string; variant: AlertVariant }) => {
    if (["left-accent", "top-accent", "subtle"].includes(props.variant)) {
        return { color: `${props.color}.600` };
    } else {
        return { color: "white" };
    }
};
