import React, { cloneElement, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { v4 as uuidv4 } from "uuid";

import { Box, BaseBoxProps, IRadio } from "Atoms";
import { getValidChildren } from "Utils";

export interface IRadioGroup {
    id?: string;
    name?: string;
    children?: React.ReactNode;
    defaultValue?: IRadio["value"];
    value?: IRadio["value"];
    themeColor?: IRadio["themeColor"];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: IRadio["value"]) => void;
    stretch?: BaseBoxProps["margin"];
    isInline?: boolean;
}

export type RadioGroupProps = IRadioGroup & Omit<BaseBoxProps, "onChange">;

export const RadioGroup: React.FC<RadioGroupProps> = forwardRef(
    (
        { onChange, name, themeColor, size, defaultValue, isInline, value: valueProp, stretch = 2, children, ...rest },
        ref
    ) => {
        const { current: isControlled } = useRef(valueProp != null);
        const [value, setValue] = useState(defaultValue || null);
        const _value = isControlled ? valueProp : value;

        const rootRef = useRef<HTMLElement>(null);

        const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!isControlled) {
                setValue(event.target.value);
            }

            if (onChange) {
                onChange(event, event.target.value);
            }
        };

        // If no name is passed, we'll generate a random, unique name
        const fallbackName = `radio-${uuidv4()}`;
        const _name = name || fallbackName;

        const validChildren = getValidChildren(children);

        type RadioChildProps = {
            size: IRadio["size"];
            themeColor: IRadio["themeColor"];
            name: IRadio["name"];
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
            isChecked: IRadio["isChecked"];
        };

        const clones = validChildren.map((child: any, index) => {
            const isLastRadio = validChildren.length === index + 1;
            const spacingProps = isInline ? { mr: stretch } : { mb: stretch };

            const childProps: RadioChildProps = {
                size: child?.props.size || size,
                themeColor: child?.props.themeColor || themeColor,
                name: _name,
                onChange: _onChange,
                isChecked: child?.props.value === _value
            };

            return (
                <Box key={index} display={isInline ? "inline-block" : "block"} {...(!isLastRadio && spacingProps)}>
                    {cloneElement(child, childProps)}
                </Box>
            );
        });

        // Calling focus() on the radiogroup should focus on the selected option or first enabled option
        useImperativeHandle(
            ref,
            () => ({
                focus: () => {
                    let input = rootRef?.current?.querySelector<HTMLInputElement>("input:not(:disabled):checked");

                    if (!input) {
                        input = rootRef?.current?.querySelector("input:not(:disabled)");
                    }

                    if (input) {
                        input?.focus();
                    }
                }
            }),
            []
        );

        return (
            <Box ref={rootRef} role="radiogroup" {...rest}>
                {clones}
            </Box>
        );
    }
);
