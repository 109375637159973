import React, { Children, cloneElement, forwardRef, isValidElement } from "react";

import { Box, BaseBoxProps } from "Atoms";
import { useTabsContext } from "../Tabs";
import { getValidChildren } from "Utils";

export type TabPanelsProps = BaseBoxProps;

export const TabPanels: React.FC<TabPanelsProps> = forwardRef(({ children, ...rest }, ref) => {
    const { index: selectedIndex, selectedPanelRef, isLazy } = useTabsContext();

    const _children = getValidChildren(children);

    const clones = _children.map((child, index) => {
        if (!isValidElement(child) || (isLazy && index !== selectedIndex)) return;
        return cloneElement(child, {
            // @ts-ignore
            isSelected: index === selectedIndex,
            selectedPanelRef
        });
    });

    return (
        <Box ref={ref} {...rest} width="100%">
            {clones}
        </Box>
    );
});
