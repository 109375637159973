import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { NewButton, ButtonProps } from "Atoms";

type Props = { to: string } & ButtonProps & PropsWithChildren;

const LinkButton: React.FC<Props> = ({ to, onClick, ...rest }) => {
    const navigate = useNavigate();
    return (
        <NewButton
            {...rest}
            onClick={(event: any) => {
                onClick && onClick(event);
                navigate(to);
            }}
        >
            {rest.children}
        </NewButton>
    );
};

export default LinkButton;
