import React, { Fragment } from "react";
import { FieldAttributes, Field, ErrorMessage } from "formik-next";

import { Radio, FormLabel, Text, Flex, FormErrorMessage, FlexProps } from "Atoms";

type IFormRadio = {
    formLabel?: any;
    helperText?: string;
    isDisabled?: boolean;
    isMandatory?: boolean;
    isInline?: boolean;
    options: { key: string; value: string }[];
    wrapperProps?: FlexProps;
};

type Props = FieldAttributes<IFormRadio>;

export const FormRadios: React.FC<Props> = ({
    formLabel,
    helperText,
    isDisabled,
    options,
    name,
    isMandatory,
    isInline = false,
    wrapperProps,
    ...rest
}) => {
    return (
        <Flex direction={isInline ? "row" : "column"} role="group" mb={6} {...wrapperProps}>
            <FormLabel>
                {formLabel}
                {isMandatory && (
                    <Text as="span" color="red.500" ml={2}>
                        *
                    </Text>
                )}
            </FormLabel>
            <Field name={name}>
                {({ field }: any) => {
                    return (
                        <Flex>
                            {options.map(option => (
                                <Fragment key={option.key}>
                                    <Radio
                                        {...field}
                                        {...rest}
                                        mr={4}
                                        id={option.value}
                                        value={option.value}
                                        checked={field.value === option.value}
                                    >
                                        {option.key}
                                    </Radio>
                                </Fragment>
                            ))}
                        </Flex>
                    );
                }}
            </Field>
            <ErrorMessage name={name} component={FormErrorMessage} />
        </Flex>
    );
};
