import React from "react";
import Truncate, { TruncateProps } from "react-truncate";

import { NewList, NewListItem, Text, NewListProps } from "Atoms";
import { Modification, Modifications } from "Types";
import { Languagekey, useLanguage } from "Providers";

type Props = {
    modifications: {
        flavours?: Modification | null;
        sizes?: Modification | null;
    };
} & NewListProps;


export const ModificationsList: React.FC<Props> = ({ modifications, ...rest }) => {
    const { translate } = useLanguage();
    return (
        <NewList listStyle="none" {...rest}>
            {Object.keys(modifications).map(key => {
                const modification = modifications[key as keyof Modifications];
                if (modification && !!modification?.name?.trim()) {
                    return (
                        <NewListItem key={key} color="gray.600">
                            {/** @ts-ignore */}
                            <Truncate width={200}>
                                <Text as="span">
                                    {translate(key as Languagekey)}: {modification.name}
                                </Text>
                            </Truncate>
                        </NewListItem>
                    );
                }
                return null;
            })}
        </NewList>
    );
};
