import React from "react";
import { IoIosEye } from "@react-icons/all-files/io/IoIosEye";
import { IoIosEyeOff } from "@react-icons/all-files/io/IoIosEyeOff";
import { IoIosClose } from "@react-icons/all-files/io/IoIosClose";
import { useField, FieldAttributes, ErrorMessage } from "formik-next";
import { isMobile } from "react-device-detect";

import { Box, FormLabel, Input, InputProps, FormErrorMessage, FormHelperText, IInput } from "Atoms";
import { InputGroup, InputRightElement, IconButton } from "Molecules";
import { useSwitch } from "CoreHooks";

type Props = FieldAttributes<InputProps> & {
    formLabel?: string;
    helperText?: string;
    isMandatory?: boolean;
    shouldShowTogglePassword?: boolean;
    size?: IInput["size"];
};

export const PasswordFormInput: React.FC<Props> = ({
    formLabel,
    helperText,
    isMandatory = false,
    size = "md",
    shouldShowTogglePassword = false,
    ...rest
}) => {
    const { open, onToggle } = useSwitch();

    const [field, meta, helpers] = useField<{}>(rest);

    const _shouldShowTogglePassword = shouldShowTogglePassword || isMobile;
    const isInvalid = meta.touched && !!meta.error;

    return (
        <Box mb={6}>
            {!!formLabel && <FormLabel isRequired={isMandatory}>{formLabel}</FormLabel>}
            {_shouldShowTogglePassword ? (
                <InputGroup size={size}>
                    <Input pr="4.5rem" type={open ? "text" : "password"} isInvalid={isInvalid} {...field} {...rest} />
                    <InputRightElement width="4.5rem">
                        <IconButton
                            type="button"
                            size="sm"
                            fontSize="1.5rem"
                            onClick={onToggle}
                            icon={open ? IoIosEyeOff : IoIosEye}
                        />
                    </InputRightElement>
                </InputGroup>
            ) : (
                <InputGroup size={size}>
                    <Input type="password" size={size} isInvalid={isInvalid} {...field} {...rest} />
                    {!!field.value && (
                        <InputRightElement width="4.5rem">
                            <IconButton
                                tabIndex={-1}
                                icon={IoIosClose}
                                onClick={() => helpers.setValue("")}
                                fontSize="22px"
                                size="sm"
                            />
                        </InputRightElement>
                    )}
                </InputGroup>
            )}
            <ErrorMessage name={field.name} component={FormErrorMessage} />
            {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};
