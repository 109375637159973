import React, { PropsWithChildren } from "react";
import { NavigateFunction } from "react-router-dom";

import { NewButton, ButtonProps } from "Atoms";

type Props = { to: string; navigate: NavigateFunction } & ButtonProps & PropsWithChildren;

const NavigationButton: React.FC<Props> = ({ to, onClick, navigate, ...rest }) => {
    return (
        <NewButton
            {...rest}
            onClick={(event: any) => {
                onClick && onClick(event);
                navigate(to);
            }}
        >
            {rest.children}
        </NewButton>
    );
};

export default NavigationButton;
