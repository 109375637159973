import React, { useEffect, useState } from "react";

import { BaseBoxProps, Box, Button, Flex, Image } from "Atoms";
import { Languages, useLanguage } from "Providers/languageProvider/LanguageProvider";

const getFlagImage = async (region: LanguageToCountry) => {
    return await import(`../../assets/images/${region}.png`).then(getImage => {
        return getImage.default;
    });
};

enum LanguageToCountry {
    SV = "sweden",
    EN = "united-kingdom",
    FI = "finland",
    DK = "denmark"
}

type OnClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => void;

const FlagButton: React.FC<{ region: LanguageToCountry; isSelected: boolean; onClick: OnClickHandler }> = ({
    region,
    isSelected,
    onClick
}) => {
    const [image, setImage] = useState<string>();

    const getFlagSrc = async () => {
        const imageSrc = await getFlagImage(region);
        setImage(imageSrc);
    };

    useEffect(() => {
        getFlagSrc();
    }, []);

    const buttonTheme = isSelected ? "blue" : "whiteAlpha";

    return (
        <Button height="auto" themeColor={buttonTheme} variant="outline" p={1} mr={1} onClick={onClick}>
            {image && <Image src={image} width="1.8rem" />}
        </Button>
    );
};

export const FlagLanguagePicker: React.FC<{ wrapperProps?: BaseBoxProps } & BaseBoxProps> = ({
    wrapperProps,
    ...rest
}) => {
    const { availableLanguages, userLanguage, onUserLanguageChange } = useLanguage();

    return (
        <Box {...wrapperProps}>
            <Flex alignItems="center" width="100%" background="white" {...rest}>
                {availableLanguages.map(lang => {
                    const lanaguageForSelection = Languages[lang as keyof typeof Languages];
                    const languageIsSelected = lanaguageForSelection === userLanguage;
                    const flagValue = LanguageToCountry[lang as keyof typeof LanguageToCountry];

                    return (
                        <FlagButton
                            key={lang}
                            region={flagValue}
                            onClick={() => onUserLanguageChange(lanaguageForSelection)}
                            isSelected={languageIsSelected}
                        />
                    );
                })}
            </Flex>
        </Box>
    );
};
