import React from "react";
import { Formik, Form } from "formik-next";
import * as Yup from "yup";

import { Button, IInput } from "Atoms";
import { useLanguage } from "Providers";
import { PasswordFormInput } from "Molecules";

export type ChangePasswordFormValues = {
    newPassword: string;
    confirmNewPassword: string;
    currentPassword: string;
    showCurrentPasswordField: boolean;
};

type Props = {
    handleChangePassword: (values: ChangePasswordFormValues) => Promise<{ path: string; message: string } | undefined>;
    showCurrentPasswordField: boolean;
    size?: IInput["size"];
};

const errorMessages = {
    sv: {
        match: "Lösenorden måste vara lika",
        required: "Obligatoriskt"
    },
    en: {
        match: "Passwords must match",
        required: "Required"
    }
};

export const ChangePasswordForm: React.FC<Props> = ({
    handleChangePassword,
    showCurrentPasswordField,
    size = "md"
}) => {
    const { translate, userLanguage } = useLanguage();

    const { match, required } = errorMessages[userLanguage];

    const changePasswordValidation = Yup.object().shape({
        newPassword: Yup.string().required(required),
        currentPassword: Yup.string().when("showCurrentPasswordField", {
            is: showCurrentPasswordField => showCurrentPasswordField,
            then: Yup.string().required(required),
            otherwise: Yup.string().notRequired()
        }),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), null], match)
            .required(required),
        showCurrentPasswordField: Yup.boolean()
    });

    return (
        <Formik<ChangePasswordFormValues>
            validationSchema={changePasswordValidation}
            initialValues={{
                newPassword: "",
                confirmNewPassword: "",
                currentPassword: "",
                showCurrentPasswordField
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                const res = await handleChangePassword(values);

                if (!!res) {
                    setErrors({ [res.path]: res.message });
                }

                setSubmitting(false);
            }}
        >
            {props => {
                const isSubmitDisabled = !props.values.newPassword || !props.isValid || props.submitCount > 0;

                return (
                    <Form>
                        {showCurrentPasswordField && (
                            <PasswordFormInput
                                name="currentPassword"
                                autoComplete="current-password"
                                placeholder={translate("currentPass")}
                                size={size}
                                isMandatory
                                fullWidth
                            />
                        )}
                        <PasswordFormInput
                            name="newPassword"
                            autoComplete="new-password"
                            placeholder={translate("newPass")}
                            size={size}
                            isMandatory
                            fullWidth
                        />
                        <PasswordFormInput
                            name="confirmNewPassword"
                            autoComplete="new-password"
                            placeholder={translate("retypeNewPassword")}
                            size={size}
                            isMandatory
                            fullWidth
                        />

                        <Button
                            mt={8}
                            fullWidth
                            isLoading={props.isSubmitting}
                            size={size}
                            themeColor="green"
                            type="submit"
                            isDisabled={isSubmitDisabled}
                        >
                            {translate("updatePassword")}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};
