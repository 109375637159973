import { BaseBoxProps, Dot, Flex } from "Atoms";
import React from "react";

export const LoadingDots: React.FC<{ numberOfDots: number } & BaseBoxProps> = ({ numberOfDots, ...props }) => {
    return (
        <Flex {...props}>
            {[...Array(numberOfDots)].map((_, index: number) => {
                return <Dot key={index}>.</Dot>;
            })}
        </Flex>
    );
};
