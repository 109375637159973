import React from "react";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";

import { Box, Input, InputProps, BaseBoxProps, IInput, PseudoBoxProps } from "Atoms";

type SelectAttributes = React.SelectHTMLAttributes<HTMLSelectElement>;

interface ISelect extends IInput<HTMLSelectElement> {
    id?: SelectAttributes["id"];
    name?: SelectAttributes["name"];
    form?: SelectAttributes["form"];
    value?: SelectAttributes["value"];
    defaultValue?: SelectAttributes["defaultValue"];
    autoFocus?: SelectAttributes["autoFocus"];
    size?: "sm" | "md" | "lg" | "xl";
    placeholder?: string;
    selectProps?: React.HTMLAttributes<HTMLSelectElement> | PseudoBoxProps;
    isDisabled?: boolean;
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
}

export type SelectProps = ISelect & InputProps & BaseBoxProps & React.RefAttributes<HTMLSelectElement>;

export const Select: React.FC<SelectProps> = ({
    size = "md",
    placeholder,
    children,
    value,
    onBlur,
    onKeyDown,
    onChange,
    onFocus,
    onKeyPress,
    onKeyUp,
    name,
    color,
    id,
    variant,
    defaultValue,
    selectProps,
    fullWidth,
    isDisabled,
    ...rest
}) => {
    const inputProps = {
        placeholder,
        value,
        onBlur,
        onKeyDown,
        onChange,
        onFocus,
        onKeyPress,
        onKeyUp,
        name,
        id,
        variant,
        defaultValue,
        fullWidth,
        isDisabled,
        color
    };

    const opacity = isDisabled ? 0.4 : undefined;
    return (
        <Box position="relative" width="100%" color={color} {...rest}>
            <Input
                as="select"
                //@ts-ignore
                size={size}
                pr={8}
                lineHeight="normal"
                appearance="none"
                width="100%"
                {...inputProps}
                {...selectProps}
            >
                {!!placeholder && <option value="">{placeholder}</option>}
                {children}
            </Input>
            <Box
                position="absolute"
                display="inline-flex"
                width="1.5rem"
                height="100%"
                alignItems="center"
                justifyContent="center"
                right="0.5rem"
                top="0"
                opacity={opacity}
                pointerEvents="none"
                zIndex={2}
            >
                <Box as={FiChevronDown} size="20px" color="currentColor" />
            </Box>
        </Box>
    );
};
