import React from "react";
import { useField, FieldAttributes } from "formik-next";

import { Textarea, FormLabel, Box, InputProps, FormErrorMessage, FormHelperText, Text, BaseBoxProps } from "Atoms";

type Props = FieldAttributes<InputProps> & {
    formLabel?: string;
    helperText?: string;
    isMandatory?: boolean;
    wrapperProps?: BaseBoxProps;
};

export const TextareaInput: React.FC<Props> = ({
    formLabel,
    helperText,
    isMandatory = false,
    wrapperProps,
    ...rest
}) => {
    const [field, meta] = useField<{}>(rest);
    const errorTxt = meta.error && meta.touched ? meta.error : "";

    return (
        <Box mb={6} {...wrapperProps}>
            {formLabel && (
                <FormLabel>
                    {formLabel}{" "}
                    {isMandatory && (
                        <Text as="span" color="red.500" ml={2}>
                            *
                        </Text>
                    )}
                </FormLabel>
            )}
            <Textarea {...field} {...rest} />
            {errorTxt && <FormErrorMessage>{errorTxt}</FormErrorMessage>}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};
