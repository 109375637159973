import React from "react";
import { AiFillFacebook } from "@react-icons/all-files/ai/AiFillFacebook";
import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram";
import { AiFillLinkedin } from "@react-icons/all-files/ai/AiFillLinkedin";

import { Box, Flex, Header, Text, Link, CopyrightText, NewDivider, Stack } from "Atoms";
import { RouterLink } from "Molecules";
import { LanguageHeader } from "Components";
import { modals, useLanguage, useModal } from "Providers";
import { useCookieConsent } from "Hooks";

export const ConsumerFooter: React.FC<{}> = () => {
    const { translate } = useLanguage();
    const { openModal } = useModal();

    const { setConsentCookie } = useCookieConsent();

    return (
        <Box as="footer" py="12" px={{ base: "4", md: "8" }}>
            <Flex direction={["column", "row"]} h="auto">
                <Flex mb={[12, 0]} flex="1">
                    <Flex direction="column">
                        <Header as="h3" size="2xl" color="newPrimary" marginBottom={[2, 12, 4]} fontFamily="qopla">
                            Qopla
                        </Header>
                    </Flex>
                </Flex>
                <Flex justify="space-between">
                    <Flex direction="column">
                        <LanguageHeader
                            tid="getToKnowUs"
                            as="h4"
                            fontSize="md"
                            fontWeight="semibold"
                            textTransform="uppercase"
                            mb={6}
                            color="gray.700"
                            letterSpacing="1px"
                            lineHeight="1.2"
                        />
                        <Stack stretch={4}>
                            <Text>
                                <Link href="https://qopla.se/#problem" color="gray.700" target="_blank">
                                    {translate("addYourRestaurant")}
                                </Link>
                            </Text>
                            <Text>
                                <Link href="https://qopla.se/#about" color="gray.700" target="_blank">
                                    {translate("aboutQopla")}
                                </Link>
                            </Text>
                            <Text>
                                <Link href="mailto:support@qopla.com?subject=Hello" color="gray.700" target="_blank">
                                    {translate("contactQopla")}
                                </Link>
                            </Text>
                            <Text color="gray.700">
                                <Link href="https://qopla.se/#hiring" color="gray.700" target="_blank">
                                    {translate("workAtQopla")}
                                </Link>
                            </Text>
                        </Stack>
                    </Flex>
                    <Flex direction="column" ml={[4, 20]}>
                        <LanguageHeader
                            tid="general"
                            as="h4"
                            fontSize="md"
                            fontWeight="semibold"
                            textTransform="uppercase"
                            mb={6}
                            color="gray.700"
                            letterSpacing="1px"
                            lineHeight="1.2"
                        />
                        <Stack stretch={4}>
                            <RouterLink
                                to="/privacy"
                                color="gray.700"
                                //@ts-ignore
                                _hover={{ cursor: "pointer", textDecoration: "underline" }}
                            >
                                <Text color="gray.700">{translate("privacyPolicy")}</Text>
                            </RouterLink>
                            <RouterLink
                                to="/terms-and-conditions"
                                color="gray.700"
                                //@ts-ignore
                                _hover={{ cursor: "pointer", textDecoration: "underline" }}
                            >
                                <Text color="gray.700" textTransform="capitalize">
                                    {translate("termsOfUseSingle")}
                                </Text>
                            </RouterLink>
                            <Link
                                color="gray.700"
                                cursor="pointer"
                                onClick={() => openModal(modals.cookieManagerModal, { setConsentCookie })}
                            >
                                Cookies
                            </Link>
                        </Stack>
                    </Flex>
                </Flex>
            </Flex>
            <NewDivider my={8} color="gray.400" />
            <Flex justify="space-between" h="auto">
                <CopyrightText color="orange.900" fontWeight="900" />

                <Stack isInline stretch={2}>
                    <Link href="https://www.facebook.com/qoplaSverige/" color="gray.700" target="_blank">
                        <Box as={AiFillFacebook} size="25px" />
                    </Link>
                    <Link href="https://www.instagram.com/qoplasverige/" color="gray.700" target="_blank">
                        <Box as={AiOutlineInstagram} size="25px" />
                    </Link>
                    <Link
                        href="https://www.linkedin.com/company/qoplaab/?originalSubdomain=se"
                        color="gray.700"
                        target="_blank"
                    >
                        <Box as={AiFillLinkedin} size="25px" />
                    </Link>
                </Stack>
            </Flex>
        </Box>
    );
};
