import React from "react";
import { useField, FieldAttributes } from "formik-next";
import { CommonProps, NamedProps } from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";

import { Box, Text, FormLabel, FormErrorMessage, FormHelperText } from "Atoms";

type OptionType = {
    value: string;
    label: string;
};
type SelectTypes = CommonProps<OptionType, true> & CreatableProps<{}, true> & NamedProps;
type Props = FieldAttributes<SelectTypes> & {
    noOptionsMessage?: ((obj: { inputValue: string }) => string | null) | undefined;
    formLabel?: string;
    isMandatory?: boolean;
    helperText?: string;
};

export const CreateableSelect: React.FC<Props> = ({
    name,
    options,
    children,
    formatCreateLabel,
    noOptionsMessage,
    formLabel,
    helperText,
    isMandatory = false,
    isMulti = false,
    ...rest
}) => {
    const [field, meta, helpers] = useField<any>(name);

    const errorTxt = meta.error ? meta.error : "";

    let selectedValues = isMulti
        ? field.value.map((value: any) => ({
              value,
              label: options.find(option => option.value === value)!.label
          }))
        : {
              value: field.value,
              label: options.find(option => option.value === field.value)?.label ?? ""
          };

    const onChangeMulti = (newOptions: Array<any> | null) => {
        if (!newOptions) {
            helpers.setValue([]);
        } else {
            helpers.setValue(newOptions.map((v: any) => v.value));
        }
    };

    const onChange = (newOption: { value: string; label: string }) => {
        helpers.setValue(newOption.value);
    };

    return (
        <Box mb={6}>
            {formLabel && (
                <FormLabel>
                    {formLabel}
                    {isMandatory && (
                        <Text as="span" color="red.500" ml={2}>
                            *
                        </Text>
                    )}
                </FormLabel>
            )}
            {/** @ts-ignore */}
            <CreatableSelect
                options={options}
                {...field}
                value={selectedValues}
                // @ts-ignore
                onChange={isMulti ? onChangeMulti : onChange}
                formatCreateLabel={formatCreateLabel}
                isMulti={isMulti}
                placeholder={rest.placeholder}
                noOptionsMessage={noOptionsMessage}
            />
            {errorTxt && <FormErrorMessage>{errorTxt}</FormErrorMessage>}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};
