import React from "react";

import { Divider, Flex, Header, Text, ButtonProps } from "Atoms";
import { colors } from "../../theme/colors";

type Props = {
    header: string;
    description: string;
} & ButtonProps;

export const PosButton: React.FC<Props> = ({ header, description, ...rest }) => {
    return (
        <Flex
            boxShadow={`0 0 0 0 #d4d4d5, 0 2px 4px 0 ${colors.blackAlpha["200"]}, 0 2px 10px 0 ${colors.blackAlpha["300"]}`}
            justifyContent="center"
            direction="column"
            cursor="pointer"
            borderRadius="5%"
            padding={4}
            width="150px"
            {...rest}
        >
            <Flex alignItems="center" height="90px" justifyContent="center">
                <Header fontSize="xl" color="gray.700" textAlign="center">
                    {header}
                </Header>
            </Flex>
            <Divider />
            <Text height="40px" color="gray.500" textAlign="center">
                {description}
            </Text>
        </Flex>
    );
};
