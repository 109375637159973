import React, { forwardRef } from "react";
import { RiAdminLine } from "@react-icons/all-files/ri/RiAdminLine";

import { Box, PseudoBox, PseudoBoxProps, Flex } from "Atoms";
import { useTabStyle } from "../tabStyles";
export interface ITabPanel {
    isSelected?: boolean;
    isDisabled?: boolean;
    selectedPanelRef?: React.RefObject<HTMLElement>;
    isSuperAdminTab?: boolean;
}
export type TabProps = ITabPanel & PseudoBoxProps;

export const Tab: React.FC<TabProps> = forwardRef((props, ref) => {
    const { isSelected, isDisabled, size, ...rest } = props;
    const tabStyleProps = useTabStyle();
    const isSuperAdminTab = !!props.isSuperAdminTab;

    return (
        <PseudoBox
            ref={ref}
            role="tab"
            tabIndex={isSelected ? 0 : -1}
            outline="none"
            as="button"
            type="button"
            disabled={isDisabled}
            aria-selected={isSelected}
            aria-disabled={isDisabled}
            {...tabStyleProps}
            {...rest}
            {...(isSuperAdminTab && { mr: 16 })}
        >
            {!isSuperAdminTab ? (
                props.children
            ) : (
                <Flex width="100%" justifyContent="space-between" alignItems="center">
                    <Box as="span" whiteSpace="nowrap">
                        {props.children}
                    </Box>
                    <Box as={RiAdminLine} mt={1} color="blue.500" />
                </Flex>
            )}
        </PseudoBox>
    );
});
