import React from "react";
import styled from "styled-components";

const ProgressBarWrapper = styled.div<{ height: string }>`
    position: relative;
    display: flex;
    height: ${props => props.height};
    width: 100%;
    background: ${props => props.theme.colors.gray[200]};
    border-radius: 0.25rem;
`;

type ProgressProps = {
    checkpoints: { label: string }[];
    percent: number;
    active?: boolean;
    color?: string;
    size?: "xs" | "sm" | "md" | "lg";
};

type ProgressBarProps = {
    percent: number;
    active?: boolean;
    color?: string;
    height?: string;
};

const progressbarSizes = {
    lg: "1rem",
    md: "0.75rem",
    sm: "0.5rem",
    xs: "0.25rem"
};

const ProgressBar = styled.div<Partial<ProgressBarProps>>`
    position: absolute;
    transition: all 0.5s ease-in-out;
    width: ${props => props.percent}%;
    height: ${props => props.height};
    background: ${props => props.color || props.theme.colors.primary};
    border-radius: 0.25rem;

    ${({ active }) =>
        active &&
        `
        &:after {
            content: "";
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #fff;
            border-radius: 0.25rem;
            animation: progress-active 2s ease infinite;
        }
    `}
`;

const Checkpoint = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #ffffff;
`;

export const Progress: React.FC<ProgressProps> = ({ checkpoints, size = "md", ...rest }) => {
    const height = progressbarSizes[size];
    return (
        <ProgressBarWrapper height={height}>
            <ProgressBar {...rest} height={height} />
            {checkpoints.map(checkpoint => (
                <Checkpoint key={checkpoint.label}>{checkpoint.label}</Checkpoint>
            ))}
        </ProgressBarWrapper>
    );
};
