import React from "react";

import { Box, BaseBoxProps, useInputStyle, IInput } from "Atoms";

interface IInputAddon {
    children: React.ReactNode;
    size?: IInput["size"];
    placement?: "left" | "right";
}

type InputAddonProps = IInputAddon & BaseBoxProps;

type SpecificInputAddon = Omit<InputAddonProps, "placement">;

const InputAddon: React.FC<InputAddonProps> = ({ placement = "left", size = "md", ...props }) => {
    const _placement = {
        left: {
            mr: "-1px",
            roundedRight: 0,
            borderRightColor: "transparent"
        },
        right: {
            order: 1,
            roundedLeft: 0,
            borderLeftColor: "transparent"
        }
    };

    const styleProps = {
        ...useInputStyle({ size, variant: "default" }),
        flex: "0 0 auto",
        whiteSpace: "nowrap",
        bg: "gray.100",
        ..._placement[placement]
    };

    return <Box {...styleProps} {...props} css={{ "input:focus + &": { zIndex: -1 } }} />;
};

const InputLeftAddon: React.FC<SpecificInputAddon> = props => <InputAddon placement="left" {...props} />;
const InputRightAddon: React.FC<SpecificInputAddon> = props => <InputAddon placement="right" {...props} />;

export { InputAddon, InputLeftAddon, InputRightAddon };
