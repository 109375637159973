import React from "react";

import { BaseBoxProps, Box } from "Atoms";

type ICircularProgressProps = {
    size?: string;
    max?: number;
    min?: number;
    thickness?: number;
    value?: number;
    trackColor?: string;
    progressColor?: string;
    trackIsRound?: boolean;
};

export type CircularProgressContentProps = BaseBoxProps;

export type CircularProgressProps = BaseBoxProps & ICircularProgressProps;

const getCircularProgressStyles = ({
    size,
    max,
    min,
    value,
    thickness,
    trackColor,
    progressColor,
    trackIsRound
}: Required<ICircularProgressProps>) => {
    let radius = 50;
    let diameter = radius * 2;
    let circumference = diameter * Math.PI;
    let strokeDasharray = Math.round(circumference * 1000) / 1000;

    let viewBox = diameter / (1 - thickness / 2);
    let viewBoxAttr = `${viewBox / 2} ${viewBox / 2} ${viewBox} ${viewBox}`;
    let strokeWidth = (thickness / 2) * viewBox;
    let progress = 1 - (value - min) / (max - min);
    let strokeDashoffset = progress * circumference;

    function getCircleProps({ thickness, offset, color }: { thickness: number; offset: number; color: string }) {
        return {
            as: "circle",
            color,
            fill: "transparent",
            stroke: "currentColor",
            strokeWidth: thickness,
            strokeDasharray: strokeDasharray,
            strokeDashoffset: offset,
            cx: viewBox,
            cy: viewBox,
            r: radius
        };
    }

    return {
        rootProps: {
            size: "1em",
            fontSize: size,
            display: "inline-block",
            position: "relative",
            veriticalAlign: "middle",
            role: "progressbar",
            "aria-valuemin": min,
            "aria-valuemax": max
        },
        svgProps: {
            as: "svg",
            viewBox: viewBoxAttr,
            verticalAlign: "top",
            size: "100%"
        },
        trackCircleProps: getCircleProps({
            thickness: strokeWidth,
            offset: 0,
            color: trackColor
        }),

        indicatorCircleProps: {
            ...getCircleProps({
                thickness: strokeWidth,
                offset: strokeDashoffset,
                color: progressColor
            }),
            strokeLinecap: trackIsRound ? "round" : "butt",
            stroke: "currentColor"
        }
    };
};

export const CircularProgress: React.FC<CircularProgressProps> = ({
    size = "48px",
    max = 100,
    min = 0,
    value = 0,
    thickness = 0.2,
    children,
    trackColor = "gray",
    progressColor = "blue",
    trackIsRound = false,
    ...rest
}) => {
    const _trackColor = `${trackColor}.100`;
    const _progressColor = `${progressColor}.500`;

    const { rootProps, indicatorCircleProps, svgProps, trackCircleProps } = getCircularProgressStyles({
        min,
        max,
        value,
        size,
        thickness,
        progressColor: _progressColor,
        trackColor: _trackColor,
        trackIsRound
    });

    return (
        <Box {...rootProps} {...rest}>
            <Box {...(svgProps as any)}>
                <Box {...(trackCircleProps as any)} data-progress-track />
                <Box {...(indicatorCircleProps as any)} data-progress-indicator />
            </Box>
            {children}
        </Box>
    );
};

export const CircularProgressContent: React.FC<CircularProgressContentProps> = props => (
    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" fontSize="0.25em" {...props} />
);
