import React from "react";

import { Flex, Text, NewList, NewListItem, FlexProps } from "Atoms";
import { Addon } from "Types";
import { mathSign } from "../admin/components/poses/pos/utils/priceUtils";
import { getTotalAddonPrice } from "../utils/newPriceHelpers";

type Props = {
    addons: Addon[] | null;
    quantityIndicator?: string;
} & FlexProps;

export const OnlineAddonsList: React.FC<Props> = ({ addons, quantityIndicator = "st", ...rest }) => {
    if (!addons || addons.length === 0) return null;

    return (
        <Flex {...rest} direction="column" width="100%">
            <NewList listStyle="none" width="100%" color="gray.600">
                {addons.map((addon, i) => {
                    const totalAddonPrice = getTotalAddonPrice(addon);
                    const sign = mathSign(totalAddonPrice);

                    return (
                        <NewListItem key={addon.name} display="flex" justifyContent="space-between">
                            <Text as="span" flex="1">
                                + {addon.quantity}
                                {quantityIndicator} {addon.name}
                            </Text>
                            <Text>
                                ({sign} {totalAddonPrice} kr)
                            </Text>
                        </NewListItem>
                    );
                })}
            </NewList>
        </Flex>
    );
};
