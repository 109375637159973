import React, { useState, cloneElement, createContext, useContext, Children, isValidElement } from "react";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";

import { Box, BaseBoxProps, PseudoBox, PseudoBoxProps, Collapsable } from "../../atoms";

const getValidChildren = (children: React.ReactNode) =>
    Children.toArray(children).filter(child => isValidElement(child));

const disabledProps = {
    _disabled: {
        cursor: "not-allowed",
        opacity: 0.45
    }
} as BaseBoxProps;

interface IAccordion {
    children: React.ReactNode;
    defaultIndex: number[];
    multi: boolean;
    toggle: boolean;
    onChange: (index: number | number[] | null) => void;
    activeIndexes: number[];
}

type AccordionProps = Partial<IAccordion> & Omit<BaseBoxProps, "onChange" | "color">;

const getActiveIndexes = (activeIndexes: number | number[], childIndex: number) => {
    if (Array.isArray(activeIndexes)) {
        return activeIndexes.includes(childIndex);
    } else {
        return activeIndexes === childIndex;
    }
};

const getInitialState = (multi: boolean, defaultIndex: number | number[]) => {
    if (multi) {
        return defaultIndex || [];
    } else {
        return defaultIndex || 0;
    }
};

const Accordion: React.FC<AccordionProps> = ({
    children,
    defaultIndex,
    activeIndexes,
    onChange,
    multi = false,
    toggle = false,
    ...rest
}) => {
    const [openIndexes, setOpenIndexes] = useState(() => getInitialState(multi, defaultIndex!));

    const isControlled = activeIndexes != null;
    const _activeIndexes = isControlled ? activeIndexes! : openIndexes;

    const validChildren = getValidChildren(children);

    return (
        <Box {...rest}>
            {validChildren.map((child, childIndex) => {
                return cloneElement(child as any, {
                    isActive: getActiveIndexes(_activeIndexes!, childIndex),
                    activeIndexes,
                    onChange: (isActive: boolean) => {
                        if (multi) {
                            if (isActive) {
                                let newIndexes = [...(_activeIndexes as number[]), childIndex];
                                !!onChange && onChange(newIndexes);
                                !isControlled && setOpenIndexes(newIndexes);
                            } else {
                                const newIndexes = (_activeIndexes as number[]).filter(
                                    activeIndex => activeIndex !== childIndex
                                );
                                !!onChange && onChange(newIndexes);
                                !isControlled && setOpenIndexes(newIndexes);
                            }
                        } else {
                            if (isActive) {
                                !!onChange && onChange(childIndex);
                                !isControlled && setOpenIndexes(childIndex);
                            } else {
                                if (toggle) {
                                    !!onChange && onChange([]);
                                    !isControlled && setOpenIndexes([]);
                                }
                            }
                        }
                    }
                });
            })}
        </Box>
    );
};

type AccordionItemRenderProps = {
    isExpanded?: boolean;
    isDisabled?: boolean;
};

type AccordionItemChildren =
    | { children(props: AccordionItemRenderProps): React.ReactNode }
    | { children: React.ReactNode };

interface IAccordionItem {
    isActive: boolean;
    isDisabled?: boolean;
    onChange: (open: boolean) => void;
    activeIndexes: any;
}

type AccordionItemProps = Partial<IAccordionItem> & PseudoBoxProps & AccordionItemChildren;

const AccordionItemContext = createContext<{
    onToggle: (open: any) => void;
    isExpanded: boolean;
    isDisabled?: boolean;
}>(null as any);

export const useAccordionItemContext = () => useContext(AccordionItemContext);

//TODO: not typescripted properly
const AccordionItem: React.FC<AccordionItemProps> = React.forwardRef(
    ({ children, isActive, isDisabled, onChange, color, ...rest }, ref) => {
        const [isExpanded, setIsExpanded] = useState(isActive || false);

        const isControlled = Boolean(isActive! != null);
        let _isExpandex = isControlled ? isActive! : isExpanded;

        const onToggle = () => {
            !!onChange && onChange(!_isExpandex);
            !isControlled && setIsExpanded(!isExpanded);
        };

        return (
            <AccordionItemContext.Provider
                value={{
                    onToggle,
                    isExpanded: _isExpandex,
                    isDisabled
                }}
            >
                <PseudoBox
                    ref={ref}
                    mb="2px"
                    borderBottom="1px"
                    borderBottomColor="gray.300"
                    borderBottomWidth="1px"
                    borderBottomStyle="solid"
                    {...rest}
                >
                    {typeof children === "function"
                        ? (children as (props: AccordionItemRenderProps) => React.ReactNode)({
                              isDisabled,
                              isExpanded: _isExpandex
                          })
                        : children}
                </PseudoBox>
            </AccordionItemContext.Provider>
        );
    }
);

type AccordionTitleProps = { disableColorTransition?: boolean } & PseudoBoxProps & React.ButtonHTMLAttributes<{}>;

const AccordionTitle: React.FC<AccordionTitleProps> = ({ onClick, disableColorTransition = false, ...props }) => {
    const { onToggle, isExpanded, isDisabled } = useAccordionItemContext();

    return (
        <PseudoBox
            type="button"
            width="100%"
            padding={4}
            display="flex"
            alignItems="center"
            justifyContent={props.justifyContent || "space-between"}
            transition="all 0.3s"
            backgroundColor={disableColorTransition ? "white" : isExpanded ? "gray.100" : "white"}
            border="none"
            disabled={isDisabled}
            onClick={(e: any) => {
                onToggle(e);
                if (onClick) {
                    onClick(e);
                }
            }}
            _hover={
                disableColorTransition
                    ? {}
                    : {
                          cursor: "pointer",
                          backgroundColor: "gray.100",
                          transition: "all 0.3s"
                      }
            }
            _focus={{
                boxShadow: "outline"
            }}
            {...disabledProps}
            {...props}
        />
    );
};

type AccordionCloseIconProps = {
    isReversed?: boolean;
} & BaseBoxProps;

const AccordionCloseIcon: React.FC<AccordionCloseIconProps> = ({ isReversed = false, ...rest }) => {
    const { isExpanded } = useAccordionItemContext();

    const UpIcon = isReversed ? FaChevronDown : FaChevronUp;
    const DownIcon = isReversed ? FaChevronUp : FaChevronDown;

    return <Box as={isExpanded ? UpIcon : DownIcon} {...rest} />;
};

const AccordionContent: React.FC<BaseBoxProps> = props => {
    const { isExpanded } = useAccordionItemContext();

    return (
        <Collapsable open={isExpanded} duration={500}>
            <Box paddingX={6} paddingY={4} transition="all 0.3s" {...props} />
        </Collapsable>
    );
};

export { Accordion, AccordionItem, AccordionTitle, AccordionContent, AccordionCloseIcon };
