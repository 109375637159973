import { useTheme } from "Providers";
import { useTabsContext } from "./Tabs";

export const baseProps = {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s",
    background: "transparent"
};

export const disabledProps = {
    _disabled: {
        opacity: 0.4,
        cursor: "not-allowed"
    }
};

const lineStyle = ({ color, isVertical }: any) => {
    const border = isVertical ? "borderLeft" : "borderBottom";
    const margin = isVertical ? "ml" : "mb";

    return {
        tabList: {
            [border]: "2px",
            borderColor: "gray.300",
            [`${border}Style`]: "solid"
        },
        tab: {
            [border]: "2px",
            borderColor: "transparent",
            borderStyle: "inherit",
            color: "currentColor",
            [margin]: "-2px",
            _selected: {
                color: `${color}.600`,
                [`${border}Color`]: "currentColor"
            },
            _active: {
                bg: "gray.200"
            },
            _disabled: {
                opacity: 0.4,
                cursor: "not-allowed"
            }
        }
    };
};

const enclosedStyle = ({ color, isVertical }: any) => {
    const border = isVertical ? "borderLeft" : "borderBottom";

    return {
        tab: {
            roundedTop: "md",
            border: "1px",
            borderColor: "transparent",
            borderStyle: "solid",
            mb: "-1px",
            _selected: {
                color: `${color}.600`,
                borderColor: "inherit",
                [`${border}Color`]: "#fff"
            }
        },
        tabList: {
            mb: "-1px",
            [border]: "1px",
            [`${border}Style`]: "solid",
            borderColor: "inherit"
        }
    };
};

export const variantStyle = (props: any) => {
    switch (props.variant) {
        case "line":
            return lineStyle(props);
        case "enclosed":
            return enclosedStyle(props);
        default:
            break;
    }
};

export const orientationStyle = ({ align, isVertical }: any) => {
    const alignments = {
        end: "flex-end",
        center: "center",
        start: "flex-start"
    };

    let tabListStyle;
    let tabStyle;

    if (!isVertical) {
        tabListStyle = {
            alignItems: "center",
            //@ts-ignore
            justifyContent: alignments[align],
            maxWidth: "full"
        };

        tabStyle = {
            height: "100%"
        };
    }

    if (isVertical) {
        tabListStyle = { flexDirection: "column" };

        tabStyle = {
            width: "100%"
        };
    }

    return {
        tabList: tabListStyle,
        tab: tabStyle
    };
};

const tabSizes = {
    sm: {
        padding: "0.25rem 1rem",
        fontSize: "0.85rem"
    },
    md: {
        fontSize: "1rem",
        padding: "0.5rem 1rem"
    },
    lg: {
        fontSize: "1.15rem",
        padding: "0.75rem 1rem"
    }
};

export const useTabStyle = () => {
    const theme = useTheme();
    const { variant, color, size, isStretched, isVertical } = useTabsContext();

    const _variantStyle = variantStyle({ variant, color, theme, isVertical });
    const _orientationStyle = orientationStyle({ isVertical });

    return {
        ...baseProps,
        ...disabledProps,
        ...tabSizes[size],
        ...(_variantStyle && _variantStyle.tab),
        ...(_orientationStyle && _orientationStyle.tab),
        ...(isStretched && { flex: 1 })
    };
};

export const useTabListStyle = () => {
    const theme = useTheme();
    const { variant, align, isVertical, color } = useTabsContext();
    const _variantStyle = variantStyle({ variant, color, theme, isVertical });
    const _orientationStyle = orientationStyle({ align, isVertical });

    return {
        ...(_variantStyle && _variantStyle.tabList),
        ...(_orientationStyle && _orientationStyle.tabList)
    };
};
