import React from "react";
import { useField, FieldAttributes } from "formik-next";

import {
    Checkbox,
    FormLabel,
    Box,
    InputProps,
    FormErrorMessage,
    FormHelperText,
    Text,
    Flex,
    FormLabelProps
} from "Atoms";

type Props = FieldAttributes<InputProps> & {
    formLabel?: string;
    helperText?: string;
    isMandatory?: boolean;
    textAlign?: "right" | "left";
    formLabelProps?: FormLabelProps;
};

export const CheckBoxInput: React.FC<Props> = ({
    formLabel,
    helperText,
    isMandatory = false,
    textAlign = "right",
    formLabelProps,
    ...rest
}) => {
    const [field, meta] = useField<{}>(rest);
    const errorTxt = meta.error && meta.touched ? meta.error : "";
    const { value } = meta;

    return (
        <Box mb={6}>
            <Flex direction={textAlign === "left" ? "row-reverse" : "row"}>
                {formLabel && (
                    <FormLabel {...formLabelProps}>
                        {formLabel}
                        {isMandatory && (
                            <Text as="span" color="red.500" ml={2}>
                                *
                            </Text>
                        )}
                    </FormLabel>
                )}
                <Checkbox checked={!!value} {...field} {...rest} />
            </Flex>
            {errorTxt && <FormErrorMessage>{errorTxt}</FormErrorMessage>}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};
