import React from "react";
import { useField, FieldAttributes } from "formik-next";

import { Input, BaseBoxProps, Box, Checkbox, FormErrorMessage, FormHelperText } from "Atoms";
import { FormInput } from "Molecules";

type Props = {
    wrapperProps?: BaseBoxProps;
} & FieldAttributes<{ formLabel?: any; helperText?: string; isDisabled?: boolean }>;

export const FormCheckboxWithInputNumber: React.FC<Props> = ({
    formLabel,
    helperText,
    isDisabled,
    wrapperProps,
    ...rest
}) => {
    const [field, meta, helpers] = useField<{}>(rest);

    const errorTxt = meta.error && meta.touched ? meta.error : "";
    const isChecked = !!field.value;
    return (
        <Box role="group" mb={6} {...wrapperProps}>
            <Box>
                <Checkbox
                    name={field.name}
                    checked={isChecked}
                    onChange={event => {
                        helpers.setValue(+event.target.checked);
                    }}
                    disabled={isDisabled}
                >
                    <Box as="span">{formLabel}</Box>
                </Checkbox>
                {errorTxt && <FormErrorMessage>{errorTxt}</FormErrorMessage>}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Box>
            {isChecked && (
                <Input
                    type="number"
                    mt={4}
                    ml={4}
                    onChange={field.onChange}
                    name={field.name}
                    defaultValue={String(field.value)}
                    placeholder="Antal"
                />
            )}
        </Box>
    );
};
