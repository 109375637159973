import React, { Children, cloneElement } from "react";

import { BaseBoxProps, Stack } from "Atoms";

export type RadioButtonGroupProps = {
    name?: string;
    children: any;
    onValueChanged?: (selectedValue: string) => void;
    isDisabled?: boolean;
    activeThemeColor?: string;
    inactiveThemeColor?: string;
    value: string | number;
    stretch?: number;
    /**
     * If `true`, the borderRadius of button that are direct children will be altered
     * to look flushed together
     */
    isAttached?: boolean;
} & BaseBoxProps;

type RadioButtonChildProps = {
    isChecked: boolean;
    themeColor: string;
    disabled?: boolean;
} & Partial<RadioButtonGroupProps>;

export const RadioButtonGroup = ({
    name,
    children,
    onValueChanged,
    value,
    isDisabled,
    activeThemeColor,
    inactiveThemeColor,
    rounded,
    stretch = 0,
    isAttached = true,
    ...rest
}: RadioButtonGroupProps) => {
    const clones = Children.map(children, (child, index) => {
        const isChecked = child?.props.value == value;
        const isFirstChild = index === 0;
        const isLastChild = index === children.length - 1;
        const onChildClicked = () => {
            onValueChanged && onValueChanged(child?.props.value);
        };

        /**
         * Note, you can have an `activeThemeColor`, `inactiveThemeColor`, both or neither
         *    activeThemeColor="green"
         *    inactiveThemeColor="blue"
         *
         * In this case, the selected radio button will be green, all others will be blue.
         *
         * When a themeColor is not specified in either case, the child's themeColor will be used.
         */
        let childThemeColor = child?.themeColor;
        if (isChecked && activeThemeColor) {
            childThemeColor = activeThemeColor;
        }
        if (!isChecked && inactiveThemeColor) {
            childThemeColor = inactiveThemeColor;
        }

        const childProps: RadioButtonChildProps = {
            name,
            onClick: onChildClicked,
            isChecked,
            themeColor: childThemeColor,
            disabled: isDisabled,
            rounded,
            ...(!isFirstChild && !isAttached && { mr: stretch }),
            ...(isFirstChild && isAttached && { roundedRight: 0 }),
            ...(isLastChild && isAttached && { roundedLeft: 0 }),
            ...(!isLastChild && isAttached && { borderRight: 0 }),
            ...(!isLastChild && !isFirstChild && isAttached && { rounded: 0 })
        };

        return cloneElement(child, childProps);
    });

    return (
        <Stack role="radiogroup" isInline={true} stretch={stretch} {...rest}>
            {clones}
        </Stack>
    );
};
