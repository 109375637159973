import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { MdNavigateNext } from "@react-icons/all-files/md/MdNavigateNext";
import { MdNavigateBefore } from "@react-icons/all-files/md/MdNavigateBefore";
import { GiPlainCircle } from "@react-icons/all-files/gi/GiPlainCircle";

import styled, { keyframes } from "styled-components";
import { Box } from "../../atoms/Box/Box";
import { Button, ButtonProps, BaseBoxProps, Flex } from "Atoms";

/** ANIMATION */
const fadedLeft = keyframes`
    0% { will-change: opacity, left; opacity: 0; left: 100%;}
    100% { will-change: opacity, left; opacity: 100; left: 0;}
`;
const fadedRight = keyframes`
    0% { will-change: opacity, right; opacity: 0; right: 100%;}
    100% { will-change: opacity, right; opacity: 100; right: 0;}
`;

const BoxExtension = styled(Box)<{ animation: AnimationDirection }>`
    position: relative;
    animation: 400ms ${props => (props.animation === "BACK" ? fadedRight : fadedLeft)} ease-in;
`;
/** */

interface IStepWizard {
    totalSteps: number;
    currentStep?: number;
    stepTitles: string[];
    animationDirection?: AnimationDirection;
    animationIsOn: boolean;
    titleAboveStepWizard: boolean;
    allTitleProps: BaseBoxProps | null | undefined;
    stepsToValidate?: ValidStep[];
    setStepsToValidate: React.Dispatch<React.SetStateAction<ValidStep[] | undefined>>;
    setStepTitles: React.Dispatch<React.SetStateAction<string[]>>;
    setCurrentStep: React.Dispatch<React.SetStateAction<number | undefined>>;
    setStepAndAnimation: (step: number, animation: AnimationDirection) => void;
}

/** TYPES */
type StepBaseProps = BaseBoxProps;
type StepWizardProps = StepBaseProps & {
    noSteps?: number;
    minHeight?: string;
    titleAboveButtons?: boolean;
    titleProps?: BaseBoxProps;
    animationOn?: boolean;
    skipToStep?: number;
    stepsToValidate?: number[];
};
type StepProps = StepBaseProps & { step: number; title: string; titleProps?: StepBaseProps };
type StepButtonProps = {
    leftButtonStyle?: ButtonProps;
    rightButtonStyle?: ButtonProps;
    backText?: string;
    forwardText?: string;
};
type AnimationDirection = "BACK" | "FORWARD";
type ValidStep = { step: number; valid: boolean };
type ValidateStepProps = {
    currentStep?: number;
    stepsToValidate?: ValidStep[];
    setValidatedStep: (isValid: boolean) => void;
};
type ValidateChildren = { children(props: ValidateStepProps): React.ReactNode };

/** CONTEXT */
const StepContext = createContext<IStepWizard>({} as any);
export const useStepWizard = () => useContext(StepContext);

/**
 * Main Step Wizard wrapper with stepWizard provider
 * @param {number} noSteps number of total steps - not need if you have steps directly as children
 * @param {BaseBoxProps} rest setting css props for the wrapper around the steps
 * @returns Step wizard provider and chidren
 *  */
const StepWizard: React.FC<StepWizardProps> = ({
    noSteps,
    titleAboveButtons = false,
    animationOn = true,
    titleProps = null,
    ...rest
}) => {
    const [currentStep, setCurrentStep] = useState<number>();
    const [stepTitles, setStepTitles] = useState<string[]>([]);
    const [animationIsOn] = useState<boolean>(animationOn);
    const [stepsToValidate, setStepsToValidate] = useState<ValidStep[] | undefined>();
    const [titleAboveStepWizard] = useState<boolean>(titleAboveButtons);
    const [allTitleProps] = useState<BaseBoxProps | null | undefined>(titleProps);
    const [animationDirection, setAnimationDirection] = useState<AnimationDirection>();
    const { children } = rest;

    const totalSteps = noSteps
        ? noSteps
        : React.Children.toArray(children).reduce((total: number, child: ReactNode) => {
              const value = React.isValidElement(child) && (child as React.ReactElement<any>).type === Step;
              return (total += value ? 1 : 0);
          }, 0);

    const setStepAndAnimation = (step: number, animation: AnimationDirection) => {
        if (animation === "BACK") {
            setCurrentStep(step - 1);
        } else {
            setCurrentStep(step + 1);
        }
        setAnimationDirection(animation);
    };

    const setStepValidations = () => {
        if (rest.stepsToValidate) {
            const validSteps: ValidStep[] = rest.stepsToValidate.map((value: number) => {
                return {
                    step: value,
                    valid: false
                };
            });
            setStepsToValidate(validSteps);
        }
    };

    useEffect(() => {
        if (rest.skipToStep) {
            if (rest.skipToStep > totalSteps) {
                setCurrentStep(1);
            } else {
                setCurrentStep(rest.skipToStep);
            }
        } else {
            setCurrentStep(1);
        }
        setAnimationDirection("FORWARD");
        setStepValidations();
    }, []);

    return (
        <StepContext.Provider
            value={{
                totalSteps,
                currentStep,
                stepTitles,
                animationDirection,
                animationIsOn,
                titleAboveStepWizard,
                allTitleProps,
                stepsToValidate,
                setStepsToValidate,
                setStepTitles,
                setCurrentStep,
                setStepAndAnimation
            }}
        >
            <Box {...rest}>{children}</Box>
        </StepContext.Provider>
    );
};

const StepHeader: React.FC<StepBaseProps> = props => (
    <Box as="header" fontWeight="bold" fontSize="md" pt={2} pb={2} {...props} />
);

const StepTitle: React.FC<BaseBoxProps> = ({ children }) => {
    const { allTitleProps } = useStepWizard();
    return (
        <Flex justifyContent="center" alignItems="center" as="h4" padding="0" {...allTitleProps}>
            {children}
        </Flex>
    );
};

/** HOOK - Alt validation */
/**
 * useStepValidation with Formik only!! include steps to validate on step wizard!.
 * @description
 * 1. if you want to validate steps without Formik, you can use the useStepWizard
 * and target the setStepsToValidate state to change to valid on current step
 * 2. Example const {isStepValid} = useStepValidation([recipient.name], errors);
 * @param {string[]} inputNames just like field name on inputs
 * @param {any} errors formik error object
 */
const useStepValidation = (inputNames: string[], errors: any) => {
    const { currentStep, stepsToValidate, setStepsToValidate } = useStepWizard();
    const [isStepValid, setIsStepValid] = useState<boolean>();

    const setValidSteps = (stepIsValid: boolean) => {
        const stepsValid = stepsToValidate?.map((value: ValidStep) => {
            return {
                step: value.step,
                valid: value.step === currentStep ? stepIsValid : value.valid
            };
        });
        setStepsToValidate(stepsValid);
    };

    const getByString = (path: string, errors: any) => {
        return path.split(".").reduce((nestedErrors, value) => (nestedErrors ? nestedErrors[value] : null), errors);
    };

    useEffect(() => {
        const hasErrors = inputNames.some((value: string) => {
            const hasValues = getByString(value, errors);
            return hasValues ? true : false;
        });
        setValidSteps(!hasErrors);
        setIsStepValid(!hasErrors);
    }, [errors]);

    return {
        isStepValid
    };
};


/**
 * Wrap Around step part form / anything etc and validate the step and validate setValidatedStep
 *
 * @description
 * 1. Wrap around step
 * 2. Props: currentStep, stepsToValidate, setStepsToValidate
 * 3. simple version when valid setStepsToValidate with true / false
 *
 * @returns {Function} { ( { ValidateStepProps (Type) } ) => { } }
 */
const ValidateStep: React.FC<ValidateChildren> = ({ children }) => {
    const { currentStep, stepsToValidate, setStepsToValidate } = useStepWizard();
    const [validateStep, setValidateStep] = useState<boolean>(true);

    const setValidatedStep = (isValid: boolean) => {
        if (validateStep != isValid) {
            setValidateStep(isValid);
        }
    };

    useEffect(() => {
        const stepsValid: ValidStep[] =
            stepsToValidate?.map((value: ValidStep) => {
                return {
                    step: value.step,
                    valid: value.step === currentStep ? validateStep : value.valid
                };
            }) ?? [];
        setStepsToValidate(stepsValid);
    }, [validateStep]);

    useEffect(() => {
        const currentStepIsValid =
            stepsToValidate?.some((value: ValidStep) => (value.step === currentStep ? value.valid : true)) ?? true;
        setValidateStep(currentStepIsValid);
    }, []);

    if (typeof children === "function") {
        const validStep: ValidateStepProps = {
            currentStep,
            stepsToValidate,
            setValidatedStep
        };
        return <>{children({ ...validStep })}</>;
    }
    return null;
};

/**
 * Step component containing children placed within
 * @param {number} step number of which step
 * @param {BaseBoxProps} rest setting css props for step box
 * @returns Step box with children
 */
const Step: React.FC<StepProps> = ({ step, title, ...rest }) => {
    const [currentTitle] = useState<string>(title);
    const { currentStep, setStepTitles, animationIsOn, titleAboveStepWizard, animationDirection } = useStepWizard();

    useEffect(() => {
        if (title) {
            setStepTitles(prev => (prev ? [...prev, title] : [title]));
        }
    }, []);

    /** This is used if the translations of the title change and the title is on top */
    useEffect(() => {
        if (currentTitle && currentStep && titleAboveStepWizard) {
            if (title) {
                if (step === 1) {
                    setStepTitles([title]);
                } else {
                    setStepTitles(prev => [...prev, title]);
                }
            }
        }
    }, [currentTitle !== title]);

    if (currentStep !== step) {
        return null;
    }

    return (
        <>
            {!titleAboveStepWizard && <StepTitle>{title}</StepTitle>}
            {!animationIsOn ? (
                <Box {...rest} pt={5} />
            ) : (
                <BoxExtension {...rest} pt={5} animation={animationDirection} />
            )}
        </>
    );
};

const StepButtonWrapper: React.FC<BaseBoxProps> = props => <Box {...props} />;
/** THIS IS A DEFAULT ONE - You can write your own
 * Standard Step buttons include back forward and circle step animation in middle
 * @description if you want to add icons and text use 'leftIcon' for back and 'rightIcon' for foward
 * @param {string} backText text for back button
 * @param {string} forwardText text for forward button
 * @returns Step button navigation
 */
const StandardStepButtons: React.FC<StepButtonProps & ButtonProps> = ({
    backText,
    forwardText,
    leftButtonStyle,
    rightButtonStyle,
    ...rest
}) => {
    const { totalSteps, stepTitles, currentStep, titleAboveStepWizard, setStepAndAnimation, stepsToValidate } =
        useStepWizard();

    const hasStepValidation = stepsToValidate?.filter((value: ValidStep) => value.step === currentStep) ?? [];
    let currentStepIsValid = true;
    if (hasStepValidation && hasStepValidation.length) {
        currentStepIsValid = hasStepValidation[0].valid;
    }

    const ForwardContentIcon = () => {
        return <Box as={MdNavigateNext} />;
    };
    const BackContentIcon = () => {
        return <Box as={MdNavigateBefore} />;
    };

    const { leftIcon, rightIcon } = rest;

    return (
        <>
            {titleAboveStepWizard && currentStep && <StepTitle>{stepTitles[currentStep - 1] ?? ""}</StepTitle>}
            <StepButtonWrapper
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ gap: "2rem" }}
                {...rest}
            >
                <Button
                    type="button"
                    {...leftButtonStyle}
                    leftIcon={leftIcon}
                    onClick={() => currentStep && setStepAndAnimation(currentStep, "BACK")}
                    disabled={currentStep === 1}
                >
                    {backText ? backText : <BackContentIcon />}
                </Button>
                <CircleIndicator />
                <Button
                    type="button"
                    {...rightButtonStyle}
                    rightIcon={rightIcon}
                    onClick={() => currentStep && setStepAndAnimation(currentStep, "FORWARD")}
                    disabled={currentStep === totalSteps || !currentStepIsValid}
                >
                    {forwardText ? forwardText : <ForwardContentIcon />}
                </Button>
            </StepButtonWrapper>
        </>
    );
};

/**
 * Circle Navigation indicator place in middle of forward and back buttons
 * @returns
 */
const CircleIndicator: React.FC<BaseBoxProps> = ({ ...rest }) => {
    const { totalSteps, currentStep } = useStepWizard();

    const circleStyles = {
        willChange: "opacity",
        transition: "opacity 400ms ease-in"
    };

    return (
        <Flex style={{ gap: "0.2rem" }} justifyContent="center" alignItems="center">
            {[...Array(totalSteps)].map((_, index: number) => {
                const activeStep = currentStep === index + 1;
                if (activeStep) {
                    return (
                        <Box
                            as={GiPlainCircle}
                            {...rest}
                            fontSize="1.4rem"
                            color="gray.800"
                            key={index}
                            {...circleStyles}
                        />
                    );
                }
                return (
                    <Box
                        as={GiPlainCircle}
                        {...rest}
                        fontSize="1rem"
                        color="gray.600"
                        opacity="0.6"
                        key={index}
                        {...circleStyles}
                    />
                );
            })}
        </Flex>
    );
};

export { StepWizard, StepHeader, Step, CircleIndicator };
export { StandardStepButtons, ValidateStep };
export { useStepValidation };
export type { StepProps, ValidStep, ValidateStepProps };
