import React from "react";
import { useField, FieldAttributes } from "formik-next";
import { CommonProps, StylesConfig } from "react-select";
import Select from "react-select";
import { intersectionBy } from "lodash";

import { Box, Text, FormLabel, FormErrorMessage, FormHelperText, BaseBoxProps } from "Atoms";

export type SelectOption = {
    label: string;
    value: string;
    isDisabled?: boolean;
    isFixed?: boolean;
    visited?: boolean;
};

type SelectTypes = CommonProps<{}, false>;
type Props = FieldAttributes<Partial<SelectTypes>> & {
    noOptionsMessage?: ((obj: { inputValue: string }) => string | null) | undefined;
    formLabel?: string;
    isMandatory?: boolean;
    helperText?: string;
    onFieldChange?: (value: any) => void;
    menuPortalTarget?: HTMLElement;
    wrapperProps?: BaseBoxProps;
    isDisabled?: boolean;
    isClearable?: boolean;
};

export const selectStyles: StylesConfig<any, any> = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
    },
    menuPortal: base => ({ ...base, zIndex: 10 }),
    menu: base => ({ ...base, zIndex: 10 })
};

export const SelectInput: React.FC<Props> = ({
    name,
    options,
    children,
    noOptionsMessage,
    formLabel,
    helperText,
    isMandatory = false,
    onFieldChange,
    wrapperProps,
    ...rest
}) => {
    const [field, meta, helpers] = useField<Array<any>>(name);

    const errorTxt = meta.error ? meta.error : "";

    const getValue = () => {
        if (options) {
            return rest.isMulti
                ? intersectionBy(options, field.value, "value")
                : options.find((option: any) => option.value === field.value);
        } else {
            return rest.isMulti ? [] : "";
        }
    };

    const onChange = (newOption: Array<any> | null) => {
        if (!newOption) {
            helpers.setValue([]);
        } else {
            if (rest.isMulti) {
                helpers.setValue(newOption.map((v: any) => v));
            } else {
                // @ts-ignore
                helpers.setValue(newOption.value);
                if (onFieldChange) {
                    // @ts-ignore
                    onFieldChange(newOption.value);
                }
            }
        }
    };

    return (
        <Box mb={6} {...wrapperProps}>
            {formLabel && (
                <FormLabel>
                    {formLabel}
                    {isMandatory && (
                        <Text as="span" color="red.500" ml={2}>
                            *
                        </Text>
                    )}
                </FormLabel>
            )}
            {/** @ts-ignore */}
            <Select
                options={options}
                {...field}
                value={getValue()}
                // @ts-ignore
                onChange={onChange}
                isMulti={rest.isMulti}
                //@ts-ignore
                placeholder={rest.placeholder}
                isDisabled={rest.isDisabled}
                // @ts-ignore
                isClearable={rest.isClearable}
                noOptionsMessage={noOptionsMessage}
                menuPortalTarget={rest.menuPortalTarget}
                styles={selectStyles}
            />
            {errorTxt && <FormErrorMessage>{errorTxt}</FormErrorMessage>}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};
