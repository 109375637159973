import React from "react";

import { useLanguage, Languagekey } from "LanguageProvider";
import { RadioButtonGroup } from "Molecules";
import { CustomRadioButton, BaseBoxProps } from "Atoms";
import { usePagination } from "Organisms";

export type PaginationFilterOption<T> = {
    accessor: number;
    translationKey: Languagekey;
    filter: (item: T, index?: number, array?: T[]) => boolean;
    isOrginal?: boolean;
};

type Props<T> = {
    filters: PaginationFilterOption<T>[];
    groupProps?: Omit<BaseBoxProps, "size">;
    childProps?: Omit<BaseBoxProps, "size">;
};

const leftRadioButton = {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    px: 3
};

const rightRadioButton = {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    px: 3
};

export const PaginationFilterGroup = <T,>({ filters, groupProps, childProps }: Props<T>) => {
    const { translate } = useLanguage();
    const { customFilter , setCustomFilter } = usePagination();

    if (!filters.length) {
        throw new Error("Filters are required");
    }

    const hasAccessors = filters.every((filter) => filter.accessor !== undefined);

    if (!hasAccessors) {
        throw new Error("All filters must have an accessor");
    }


    const handleFilter = (accessor: number) => {
        const filter = filters.find((filter) => filter.accessor === accessor);
        if (filter) {
            setCustomFilter(filter);
        }
    };


    const customRadioButtons = filters.map((filter, index) => {
        const isLast = index === filters.length - 1;
        return (
            <CustomRadioButton
                {...(index === 0 && { ...leftRadioButton })}
                {...(isLast && { ...rightRadioButton })}
                key={filter.translationKey}
                value={filter.accessor}
                {...childProps}
            >
                {translate(filter.translationKey)}
            </CustomRadioButton>
        );
    });

    return (
        <RadioButtonGroup
            value={customFilter?.accessor || filters[0].accessor}
            activeThemeColor="blue"
            inactiveThemeColor="gray"
            onValueChanged={(selectedValue: string) => { 
                handleFilter(selectedValue as unknown as number);
            }}
            {...groupProps}
        >
            {customRadioButtons}
        </RadioButtonGroup>
    );
};
