import React, { createContext, useContext, cloneElement, PropsWithChildren } from "react";
import { FiCheck } from "@react-icons/all-files/fi/FiCheck";
import { FiX } from "@react-icons/all-files/fi/FiX";

import { Box, Text, Flex } from "../../atoms";
import { getValidChildren } from "../../utils/renderHelpers";

interface ISteps {
    size?: "sm" | "md" | "lg";
    activeIndex: number;
    currentStatus?: "process" | "error" | "success";
    themeColor?: string;
    isVertical?: boolean;
    isReversed?: boolean;
    count?: number;
}

interface IStepItem {
    isActive: boolean;
    index: number;
    activeIndex: number;
    isLast: boolean;
    isFirst: boolean;
    hasPassed: boolean;
    themeColor: string;
    isVertical: boolean;
    isReversed?: boolean;
    currentStatus: "process" | "error" | "success";
    count: number;
}

type IStepItemCtx = Omit<IStepItem, "isFirst">;

type Steps = {};

const StepsItemContext = createContext<IStepItemCtx>(null as any);
const useStepsItemContext = () => useContext(StepsItemContext);

export const Steps: React.FC<ISteps & PropsWithChildren> = ({
    children,
    activeIndex,
    isVertical = true,
    themeColor = "blue",
    currentStatus = "process",
    isReversed = false
}) => {
    const validChildren = isReversed ? getValidChildren(children).reverse() : getValidChildren(children);

    const stepsStyles = isVertical
        ? undefined
        : {
              display: "flex",
              justifyContent: "space-between"
          };

    return (
        <Box {...stepsStyles}>
            {validChildren.map((child, childIndex) => {
                return cloneElement(child as any, {
                    isActive: activeIndex === childIndex,
                    index: childIndex,
                    activeIndex,
                    isLast: validChildren.length - 1 == childIndex,
                    isFirst: childIndex == 0,
                    hasPassed: isReversed ? activeIndex <= childIndex : activeIndex > childIndex,
                    themeColor,
                    isVertical,
                    currentStatus,
                    isReversed,
                    count: isReversed ? validChildren.length - childIndex : childIndex + 1
                });
            })}
        </Box>
    );
};

const StepItemTrail: React.FC<{}> = () => {
    const { isLast, hasPassed, themeColor, currentStatus, activeIndex, index, isReversed } = useStepsItemContext();

    const prevStep = isReversed ? activeIndex === index : activeIndex - 1 === index;

    const borderColor = currentStatus == "error" && prevStep ? "red.500" : hasPassed ? `${themeColor}.500` : "gray.500";

    return !isLast ? (
        <Box
            top="44px"
            bottom="0"
            left="18px"
            borderLeftWidth="1px"
            borderLeftStyle="solid"
            borderColor={borderColor}
            position="absolute"
        />
    ) : null;
};

const StepItemIcon: React.FC<{}> = () => {
    const { isActive, currentStatus, hasPassed, themeColor, count } = useStepsItemContext();

    const baseStyles = {
        borderStyle: "solid",
        borderWidth: "1px"
    };

    const passedStyles = {
        bg: hasPassed ? `${themeColor}.500` : undefined,
        borderColor: hasPassed ? `${themeColor}.500` : "gray.500",
        color: hasPassed ? `white` : "gray.500"
    };

    const errorStyles = {
        borderColor: "red.500",
        bg: "red.500",
        color: " white",
        boxShadow: "outline"
    };
    const processStyles = {
        borderColor: `${themeColor}.500`,
        bg: isActive ? `${themeColor}.500` : "gray.500",
        color: "white",
        boxShadow: "outline"
    };

    const activeStyles = {
        process: processStyles,
        error: errorStyles,
        success: processStyles
    };

    const styles = isActive ? activeStyles[currentStatus] : passedStyles;

    return (
        <Flex
            align="center"
            justify="center"
            size="30px"
            borderRadius="full"
            position="absolute"
            top="3px"
            left="3px"
            textAlign="center"
            {...styles}
            {...baseStyles}
        >
            {hasPassed && !isActive ? (
                <Box as={FiCheck} size="18px" />
            ) : currentStatus === "error" && isActive ? (
                <Box as={FiX} size="18px" />
            ) : currentStatus === "success" && isActive ? (
                <Box as={FiCheck} size="18px" />
            ) : (
                <Text as="span">{count}</Text>
            )}
        </Flex>
    );
};

const StepItemContent: React.FC<{ title: string; description?: string }> = ({ title, description }) => {
    const { isActive, currentStatus } = useStepsItemContext();

    const colors = isActive && currentStatus === "error" ? "red.500" : isActive ? "gray.800" : "gray.600";

    return (
        <Box display="inline-block" position="relative">
            <Text fontSize="lg" color={colors} m="0" lineHeight="tall">
                {title}
            </Text>
            {description && (
                <Text mt={4} mb={0} color={colors}>
                    {description}
                </Text>
            )}
        </Box>
    );
};

export const StepItem: React.FC<Partial<IStepItem> & { title: string; description?: string }> = ({
    isActive,
    index,
    activeIndex,
    isLast,
    isFirst,
    hasPassed,
    title,
    description,
    themeColor,
    isVertical,
    currentStatus,
    isReversed,
    count
}) => {
    const stepItemStyles = isVertical
        ? { pb: "30px", mt: isFirst ? 0 : 4 }
        : { flexBasis: "25%", flexGrow: 1, flexSrink: 1 };

    return (
        <StepsItemContext.Provider
            value={{
                isActive: isActive!,
                hasPassed: hasPassed!,
                index: index!,
                activeIndex: activeIndex!,
                isLast: isLast!,
                themeColor: themeColor!,
                isVertical: isVertical!,
                currentStatus: currentStatus!,
                isReversed: isReversed!,
                count: count!
            }}
        >
            <Box {...stepItemStyles} pl="44px" overflow="hidden" position="relative">
                <StepItemIcon />
                <StepItemContent title={title} description={description} />
                <StepItemTrail />
            </Box>
        </StepsItemContext.Provider>
    );
};
