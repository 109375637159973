import React from "react";

import { NewGrid as Grid } from "Atoms";

type Props = {
    header?: any;
    sideNavigation?: any;
    main: any;
    container?: any;
    footer?: any;
};

export const PageLayout: React.FC<Props> = ({ header, sideNavigation, main, footer, container: Container }) => {
    return (
        <Grid
            h="full"
            templateAreas={`
                "header header"
                "sideNavigation main"
                "footer footer"
            `}
            templateColumns="auto 1fr"
            templateRows="auto 1fr auto"
        >
            {header && <Grid area="header">{header}</Grid>}
            {sideNavigation && <Grid area="sideNavigation">{sideNavigation}</Grid>}

            <Grid area="main">
                <Container>{main}</Container>
            </Grid>
            {footer && <Grid area="footer">{footer}</Grid>}
        </Grid>
    );
};
